import Knuttel from "../img/people/marc_knuttel.webp";
import Shaulis from "../img/people/jon_shaulis.webp";
import Wagenaar from "../img/people/stephanie_wagenaar.webp";
import Foo from "../img/people/ken_foo.webp";
import Smith from "../img/people/mike_smith.webp";
import Bruder from "../img/people/jennifer_bruder.webp";
import Gandhi from "../img/people/nupur_gandhi.webp";
import Acton from "../img/people/nic_acton.webp";
import McBeth from "../img/people/kevin_mcbeth.webp";
import Virella from "../img/people/tony_virella.webp";
import Lovell from "../img/people/dominic_lovell.webp";
import Putro from "../img/people/dji_putro.webp";
import Hanson from "../img/people/brenda_hanson.webp";
import Sharma from "../img/people/amit_sharma.webp";
import Andrew from "../img/people/andrew_paul_acosta.webp";
import Albert from "../img/people/albert_chiwara.webp";
import Boris from "../img/people/boris_gilijamse.webp";
import Francois from "../img/people/francois_biller.webp";
import Mack from "../img/people/cliff_mack.webp";
import Balazs from "../img/people/ryan_balazs.webp";
import Wehrung from "../img/people/andrea_wehrung.webp";
import March from "../img/people/craig_march.webp";
import Ochoa from "../img/people/shae_ochoa.webp";
import Miller from "../img/people/jason_miller.webp";
import Latorre from "../img/people/ariadne_latorre.webp";
import Johnson from "../img/people/jeff_johnson.webp";
import Rice from "../img/people/gayle_rice.webp";
import Carbone from "../img/people/matthew_carbone.webp";
import Kolawole from "../img/people/adetola_kolawole.webp";
import Rutvik from "../img/people/rutvik_ahire.webp";
import Khaled from "../img/people/khaled_al_sayer.webp";
import Reilly from "../img/people/emmett_reilly.webp";
import Bagboudarian from "../img/people/vera_bagboudarian.webp";
import Iftikhar from "../img/people/raja_iftikhar.webp";
import Grapes from "../img/people/karen_grapes.webp";
import Bamburg from "../img/people/amy_bamburg.webp";
import Palamadai from "../img/people/gopi_palamadai.webp";
import Affen from "../img/people/daniel_affen.webp";
import Mortimer from "../img/people/edward_mortimer.webp";
import Braham from "../img/people/fares_braham.webp";
import Barnes from "../img/people/bearden_barnes.webp";
import Khimina from "../img/people/svetlana_khimina.webp";
import Kidd from "../img/people/jacinda_kidd.webp";
import Fiaz from "../img/people/ali_fiaz.webp";
import Magsamen from "../img/people/benjamin_magsamen.webp";
import Sripelly from "../img/people/rajesh_sripelly.webp";
import Nellas from "../img/people/christopher_nellas.webp";
import Alali from "../img/people/mohammed_alali.webp";
import Cavalluzo from "../img/people/andrea_cavalluzzo.webp";
import Peart from "../img/people/steve_peart.webp";
import ODonovan from "../img/people/john_odonovan.webp";
import Kloub from "../img/people/iyad_kloub.webp";
import Chumongkhon from "../img/people/visanu_chumongkhon.webp";
import Hinds from "../img/people/teri_hinds.webp";
import Williams from "../img/people/donald_williams.webp";
import Turner from "../img/people/madison_turner.webp";
import Elella from "../img/people/khaled_elella.webp";
import Colak from "../img/people/ali_colak.webp";
import Canales from "../img/people/robert_canales.webp";
import Aref from "../img/people/zohaib_aref.webp";
import Desai from "../img/people/krupesh_desai.webp";
import Bush from "../img/people/courtney_bush.webp";
import Edwards from "../img/people/michael_edwards.webp";
import Willems from "../img/people/jonas_willems.webp";
import Eaves from "../img/people/sara_eaves.webp";
import Allen from "../img/people/waleed_allen.webp";
import Narain from "../img/people/shastria_narain.webp";
import Matsinhe from "../img/people/diana_matsinhe.webp";

const testimonials: Testimonial[] = [
  {
    id: "general-000",
    topic: "Data Strategy",
    name: "Cliff Mack",
    img: Mack,
    title: "Data Governance Architect",
    short: "The study groups and the test-taking strategies are excellent.",
    content: "The study groups and the test-taking strategies are excellent.",
  },
  {
    id: "general-001",
    topic: "general",
    name: "Marc Knüttel",
    img: Knuttel,
    title: "Senior Manager of Enterprise Data Architecture",
    short: "Data Strategy Professionals offers preparation in a very practical, in a very straightforward way",
    content: `Data Strategy Professionals offers preparation in a very practical, in a very straightforward way, cutting out all the noise to get people ready for a proper career in this area.`,
    plainText: `Data Strategy Professionals offers preparation in a very practical, in a very straightforward way, cutting out all the noise to get people ready for a proper career in this area.`,
  },
  {
    id: "general-002",
    topic: "Data Strategy",
    name: "Marc Knüttel",
    img: Knuttel,
    title: "Senior Manager of Enterprise Data Architecture",
    short: "Data Strategy Professionals offers preparation in a very practical, in a very straightforward way",
    content: `Data Strategy Professionals offers preparation in a very practical, in a very straightforward way, cutting out all the noise to get people ready for a proper career in this area.
      The purpose of the Data Strategy Professionals <a href='https://www.datastrategypros.com/products/cdmp-question-sets' target='_blank' rel='noreferrer'>Question Sets</a> and 
      <a href='https://www.datastrategypros.com/products/cdmp-fundamentals-notes' target='_blank' rel='noreferrer'>Notes</a> is really to help someone. Your heart was in the right place when creating this. 
      Thank you for helping out the community in the right way.`,
  },
  {
    id: "general-003",
    topic: "Data Strategy",
    name: "Stephanie Wagenaar",
    img: Wagenaar,
    title: "Director of Digital Science",
    short: "Huge fan of her LinkedIn group",
    content: `I can highly recommend joining the <a href='https://www.facebook.com/groups/346145433213551/' target='_blank'rel='noreferrer'>
      CDMP Study Group</a> of Nicole Janeway. She provides great tips, summaries, and, most importantly, motivation to keep on going!
      I am also a huge fan of her LinkedIn group, <a href='https://www.linkedin.com/groups/13951141/' target='_blank' rel='noreferrer'>
      Data Strategy Professionals on LinkedIn</a>, as it provides great resources.`,
  },
  {
    id: "general-004",
    topic: "Data Strategy",
    name: "Jon Shaulis",
    img: Shaulis,
    title: "Enterprise Data Architect",
    short: "Helpful resources and support",
    content: `Since achieving my <a href='https://www.datastrategypros.com/resources/cdmp-fundamentals-exam' target='_blank' rel='noreferrer'>CDMP</a>,
    hundreds of people have reached out to network, collaborate, comment, learn, and embolden their careers as data professionals. 
    Nicole Janeway Bills of <strong>Data Strategy Professionals</strong> has become a pinnacle in this community. 
    She has done so much more than just start a <a href='https://www.facebook.com/groups/346145433213551/' target='_blank'rel='noreferrer'>study group</a>,
    she has also compiled helpful resources and responded back to others in the community who have questions or need support.`,
    plainText: `Since achieving my <a href='https://www.datastrategypros.com/resources/cdmp-fundamentals-exam' target='_blank' rel='noreferrer'>CDMP</a>,
    hundreds of people have reached out to network, collaborate, comment, learn, and embolden their careers as data professionals. 
    Nicole Janeway Bills of <strong>Data Strategy Professionals</strong> has become a pinnacle in this community. 
    She has done so much more than just start a <a href='https://www.facebook.com/groups/346145433213551/' target='_blank'rel='noreferrer'>study group</a>,
    she has also compiled helpful resources and responded back to others in the community who have questions or need support.`,
  },
  {
    id: "general-005",
    topic: "Data Strategy",
    name: "Jason Miller",
    img: Miller,
    title: "Systems Architect",
    content: `I've owned the <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
    <em>DMBOK</em></a> and used it as a reference since 2018. It is a dense book. I've used it multiple times, 
    and I seem to find something new all the time.  It's a lot of information, and it can be confusing, 
    so thank you — hats off to Data Strategy Professionals.`,
    plainText: `I've owned the <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
    <em>DMBOK</em></a> and used it as a reference since 2018. It is a dense book. I've used it multiple times, 
    and I seem to find something new all the time.  It's a lot of information, and it can be confusing, 
    so thank you — hats off to Data Strategy Professionals.`,
  },
  {
    id: "general-006",
    topic: "Data Strategy",
    name: "Nupur Gandhi",
    img: Gandhi,
    title: "Senior Business Data Consultant",
    content: `I was looking for some study groups, and I found your
    <a href="https://www.facebook.com/groups/346145433213551" target="_blank" rel="noreferrer"> 
    group on Facebook</a> and you were so warm and approachable right from the get-go.
    There was so much support and resources available, also on your
    <a href="https://www.linkedin.com/groups/13951141/" target="_blank" rel="noreferrer">LinkedIn group</a>.`,
  },
  {
    id: "general-007",
    topic: "Data Strategy",
    name: "Khaled Al-Sayer, CDMP",
    title: "Manager Data Culture and Capabilities",
    img: Khaled,
    content: `I would like to take a moment to extend my heartfelt appreciation and gratitude to key individuals who have played instrumental roles
    in my journey towards achieving the most prestigious certificate in data.
    Their unwavering dedication and expertise have been invaluable, and I am truly grateful for the impact they have had on my professional development.<br><br>
    "A special note of gratitude goes to <a href='https://www.datastrategypros.com/about' target='_blank' rel='noreferrer'>Nicole Janeway Bills</a> for her steadfast dedication to supporting data management learners.
    Your commitment to fostering a conducive learning environment has made a significant difference in my educational experience.`,
  },
  {
    id: "general-008",
    topic: "Data Strategy",
    name: "Kevin McBeth",
    img: McBeth,
    title: "MLOps Engineer",
    content: "Truly a pleasure spending an hour with the group, and thanks for all the book reviews and anti reviews!",
  },
  {
    id: "general-009",
    topic: "Data Strategy",
    name: "Adetola Kolawole",
    img: Kolawole,
    title: "Data Governance and Quality Professional",
    short: "Super helpful",
    content: "Thank you for the study materials — they were super helpful.",
    plainText: "Thank you for the study materials — they were super helpful.",
  },
  {
    id: "general-010",
    topic: "Data Strategy",
    name: "Benjamin Magsamen",
    img: Magsamen,
    title: "Data Consultant",
    content: "Your material you put out on the test prep was helpful, so thank you for that.",
    plainText: "Your material you put out on the test prep was helpful, so thank you for that.",
  },
  {
    id: "general-011",
    topic: "Data Strategy",
    name: "Donald Williams",
    img: Williams,
    title: "Data Governance Consultant",
    short: "Data Strategy Professionals has been a tremendous help",
    content: `Your resources and expertise were invaluable in this endeavor [to pass the <a href='https://www.datastrategypros.com/resources/cdmp-fundamentals-exam' target='_blank' rel='noreferrer'>CDMP</a>]. Data Strategy Professionals has been a tremendous help throughout this process.`,
    plainText: `Your resources and expertise were invaluable in this endeavor[to pass the CDMP]. Data Strategy Professionals has been a tremendous help throughout this process.`,
  },
  {
    id: "general-012",
    topic: "Data Strategy",
    name: "Khaled Abou Elella",
    img: Elella,
    title: "Data Governance Manager",
    short: "Data Strategy Professionals valuable support for data practitioners",
    content: `Thank you Data Strategy Professionals for your valuable contributions in sharing knowledge and providing support for professionals in the field of data management, which make a significant impact.`,
    plainText: `Thank you Data Strategy Professionals for your valuable contributions in sharing knowledge and providing support for professionals in the field of data management, which make a significant impact.`,
  },
  {
    id: "general-013",
    topic: "Data Strategy",
    name: "Robert Canales",
    img: Canales,
    title: "Implementation Specialist",
    short: "excellent content and materials!",
    content: `Wonderful resources were very helpful in understanding the CDMP materials and passing the test.  Thank you for the excellent content and materials!`,
    plainText: `Wonderful resources were very helpful in understanding the CDMP materials and passing the test.  Thank you for the excellent content and materials!`,
  },
  {
    id: "general-014",
    topic: "Data Strategy",
    name: "Sara Eaves",
    img: Eaves,
    title: "Assistant Director of Policy & Planning and Data Governance",
    short: "Very grateful",
    content: `Very grateful to have had support from Data Strategy Professionals while preparing for the <a href='https://www.datastrategypros.com/resources/cdmp-fundamentals-exam' target='_blank' rel='noreferrer'>CDMP</a>.`,
    plainText: `Very grateful to have had support from Data Strategy Professionals while preparing for the CDMP.`,
  },
  {
    id: "general-015",
    topic: "Data Strategy",
    name: "Waleed Allen",
    img: Allen,
    title: "Executive Director — Data Analytics and Performance Management",
    short: "Thanks for all the tips and guidance",
    content: `I subscribed to your website and used it to guide me through <a href='https://www.datastrategypros.com/resources/cdmp-fundamentals-exam' target='_blank' rel='noreferrer'>CDMP exam</a>. I passed the exam yesterday with above 80. Thanks for all the tips and guidance :)`,
    plainText: `I subscribed to your website and used it to guide me through CDMP exam. I passed the exam yesterday with above 80. Thanks for all the tips and guidance :)`,
  },
  {
    id: "general-016",
    topic: "Data Strategy",
    name: "Shastria Narain",
    img: Narain,
    title: "Senior Geographic Information Management Engineer",
    short: "Your assistance made a big difference for me.",
    content: `Thank you so much for all your support! Your assistance made a big difference for me.`,
    plainText: `Thank you so much for all your support! Your assistance made a big difference for me.`,
  },
  {
    id: "general-017",
    topic: "Data Strategy",
    name: "Diana Matsinhe",
    img: Matsinhe,
    title: "Senior Specialist: Data Quality Assurance",
    short: "You’re helping me a lot",
    content: `I completed with success the  <a href='https://www.datastrategypros.com/resources/cdmp-fundamentals-exam' target='_blank' rel='noreferrer'>CDMP Fundamentals Exam</a> some time back, and I have now the certification for CDMP Associate. I want to thank you and the CDMP community for the materials and tips that you always shared. You’re helping me a lot.`,
    plainText: `I completed with success the CDMP Fundamentals Exam some time back, and I have now the certification for CDMP Associate. I want to thank you and the CDMP community for the materials and tips that you always shared. You’re helping me a lot.`,
  },
  {
    id: "cdmp-study-plan-000",
    topic: "Data Strategy",
    name: "Amy Bamburg",
    img: Bamburg,
    title: "Data Enablement and Administration Leader",
    short: "I am following the Study Plan",
    content: `I am following the <a href='https://www.datastrategypros.com/products/cdmp-study-plan' target='_blank' rel='noreferrer'>Study Plan</a>
    so that I can work on my memorization and motivation to take this CDMP exam and continue on to pursue my Master certification.`,
    plainText: `I am following the Study Plan so that I can work on my memorization and motivation to take this CDMP exam and continue on to pursue my Master certification.`,
  },
  {
    id: "cdmp-study-plan-001",
    topic: "Data Strategy",
    name: "Jon Shaulis",
    img: Shaulis,
    title: "Enterprise Data Architect",
    short: "The painstaking detail in the guide is as apparent as its value",
    content: `Since achieving my CDMP, hundreds of people have reached out to network, collaborate, comment, learn, and embolden their careers as data professionals. 
    I personally reviewed every email, link, and reference throughout the <a href='https://www.datastrategypros.com/products/cdmp-study-plan' target='_blank' rel='noreferrer'>CDMP Study Plan</a> and can verify it is a labor of love. 
    The painstaking detail in the guide is apparent, as is its value.`,
  },
  {
    id: "cdmp-study-plan-002",
    topic: "Data Strategy",
    name: "Mike Smith",
    img: Smith,
    title: "Database Analyst",
    short: "A great study system that really worked for me",
    content: `This morning I took the <a href='https://www.datastrategypros.com/resources/cdmp-fundamentals-exam' target='_blank' rel='noreferrer'>CDMP Fundamentals Exam</a>
      and I'm happy to say that I passed with a score of 83. Throughout my study process, I utilized the 
      <a href='https://www.datastrategypros.com/products/cdmp-study-plan' target='_blank' rel='noreferrer'>CDMP Study Plan</a> and it worked great.
      I also purchased the <a href='https://www.datastrategypros.com/products/cdmp-fundamentals-notes' target='_blank' rel='noreferrer'>
      CDMP Fundamentals Notes</a> and those were very helpful too. So a big thank you to Data Strategy Professionals for providing a great study system that really worked for me.`,
  },
  {
    id: "cdmp-study-plan-003",
    topic: "Data Strategy",
    name: "Nupur Gandhi",
    img: Gandhi,
    title: "Senior Business Data Consultant",
    short: "Breaks it down into small steps",
    content: `The email <a href='https://www.datastrategypros.com/products/cdmp-study-plan' target='_blank' rel='noreferrer'>Study Plan</a>
    sent over the course of 90 days was a great additional support
    that I needed. Like, it literally told me, this is what you should read.<br/><br/>
    "We all know the <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
    <em>DMBOK</em></a> such a huge body of knowledge, and if you are trying to take the exam,
    you really need to break it down into small steps that you can digest.<br/><br/>
    "[Data Strategy Professionals] has taken the pain to do all of that for all of you. So if you're ever thinking of a
    <a href='https://www.datastrategypros.com/resources/cdmp-fundamentals-exam' target='_blank' rel='noreferrer'>CDMP</a>,
    I would highly urge that all of you either be a part of the
    <a href="https://www.facebook.com/groups/346145433213551" target="_blank" rel="noreferrer">study group</a>, 
    join the <a href="https://www.linkedin.com/groups/13951141/" target="_blank" rel="noreferrer">Data Strategy Professionals group on LinkedIn</a>,
    and alongside feel free to avail her <a href='https://www.datastrategypros.com/products/cdmp-study-plan' target='_blank' rel='noreferrer'>Study Plan</a>,
    which is so helpful.`,
  },
  {
    id: "cdmp-study-plan-004",
    topic: "Data Strategy",
    name: "Jon Shaulis",
    img: Shaulis,
    title: "Enterprise Data Architect",
    short: "Maximize your time and energy while studying",
    content: `I've already passed the exam, but I learned even more from reviewing the
    <a href='https://www.datastrategypros.com/products/cdmp-study-plan' target='_blank' rel='noreferrer'>Study Plan</a>.
    If you've chosen to pursue your CDMP, then I'm sure you know it's about much more than a test score.
    [Data Strategy Professionals] recognizes this and the study plan also features interview questions and thought-provoking external resources like videos, articles, and books related to each chapter.<br/><br/>
    "The study schedule is designed to take advantage of primacy and spaced repetition so that you can maximize your time and energy while studying.
    By following this approach, you'll put the most important chapters first and prioritize your review.
    "The plan also features nine science-backed study tips that will help you attain the CDMP and whatever certification you choose to pursue next.<br/><br/>
    Nicole is a knowledgeable and strong resource in the data community, and this guide is definitely a great alternative to trying to piece together a study method yourself.`,
  },
  {
    id: "cdmp-study-plan-005",
    topic: "Data Strategy",
    name: "Jon Shaulis",
    img: Shaulis,
    title: "Enterprise Data Architect",
    short: "Painstaking detail in the guide is apparent, as is its value",
    content: `The <a href='https://www.datastrategypros.com/products/cdmp-study-plan' target='_blank' rel='noreferrer'>Study Plan</a> is a 90-day journey that details how, when, what, and where to prepare yourself for the
    exam. I personally reviewed every email, link, and reference throughout the guide and can verify it is a
    labor of love. The painstaking detail in the guide is apparent, as is its value.`,
    plainText: `The Study Plan is a 90-day journey that details how, when, what, and where to prepare yourself for the
    exam. I personally reviewed every email, link, and reference throughout the guide and can verify it is a
    labor of love. The painstaking detail in the guide is apparent, as is its value.`,
  },
  {
    id: "cdmp-study-plan-006",
    topic: "Data Strategy",
    name: "Nic Acton",
    img: Acton,
    title: "DevSecOps Engineer",
    short: "Makes [studying] much more manageable",
    content: `This incredible <a href='https://www.datastrategypros.com/products/cdmp-study-plan' target='_blank' rel='noreferrer'>email course</a>
    [for the <a href='https://www.datastrategypros.com/resources/cdmp-fundamentals-exam' target='_blank' rel='noreferrer'>CDMP Fundamentals Exam</a>]
    splits the exam materials up over 90 days, making the otherwise overbearing
    study materials much more manageable for hard-working professionals. The CDMP exam provides the best
    practices and fundamentals for data strategy and execution.`,
    plainText: `This incredible email course splits the exam materials up over 90 days, making the otherwise overbearing
    study materials much more manageable for hard-working professionals. The CDMP exam provides the best
    practices and fundamentals for data strategy and execution.`,
  },
  {
    id: "cdmp-study-plan-007",
    topic: "Data Strategy",
    name: "Shalini Kanagala",
    title: "Senior Data Engineer",
    short: "Kept me on track with the schedule",
    content: `I signed up for 90 Day <a href='https://www.datastrategypros.com/products/cdmp-study-plan' target='_blank' rel='noreferrer'>CDMP Study Plan</a> in September – it was great and kept me on track
    with the schedule. I didn't realize I read around 600 pages! Then I passed with flying colors in
    December.`,
  },
  {
    id: "cdmp-study-plan-008",
    topic: "Data Strategy",
    img: Boris,
    name: "Boris Gilijamse",
    title: "Master Data Coordinator",
    short: "All's well thanks to your Study Plan!",
    content: `I have managed to pass the <a href='https://www.datastrategypros.com/resources/cdmp-fundamentals-exam' target='_blank' rel='noreferrer'>CDMP Fundamentals Exam</a>
    today with 73 correct answers!  All's well thanks to your <a href='https://www.datastrategypros.com/products/cdmp-study-plan' target='_blank' rel='noreferrer'>Study Plan</a>! Now the real journey can begin!`,
  },
  {
    id: "cdmp-study-plan-009",
    topic: "Data Strategy",
    name: "Jennifer Bruder",
    img: Bruder,
    short: "I recommend",
    title: "Data & Analytics Application Manager",
    content: `I recommend the Data Strategy Profesionals <a href='https://www.datastrategypros.com/resources/cdmp-fundamentals-exam' target='_blank' rel='noreferrer'>CDMP Study Plan</a>... for 12 weeks she will send you emails on what to read/study for the exam.`,
  },
  {
    id: "cdmp-study-plan-010",
    topic: "Data Strategy",
    name: "Francois Bill",
    img: Francois,
    title: "Data Project Manager",
    short: "I appreciate the neuroscience-based study tips",
    content: `I find the emails [of the <a href='https://www.datastrategypros.com/products/cdmp-study-plan' target='_blank' rel='noreferrer'>CDMP Study Plan</a>]
    valuable and not too long. Plus, I appreciate the neuroscience-based study tips.`,
  },
  {
    id: "cdmp-study-plan-011",
    topic: "Data Strategy",
    name: "Mohammed Alali",
    img: Alali,
    title: "Ecosystem Developer",
    short: "Tons of words cannot express my gratitude",
    content: `I would like to inform you that I have PASSED the <a href='https://www.datastrategypros.com/resources/cdmp-fundamentals-exam' target='_blank' rel='noreferrer'>exam</a>
    and I am a Certified Data Management Professional officially 
    and thank you for your effort on the <a href='https://www.datastrategypros.com/products/cdmp-study-plan' target='_blank' rel='noreferrer'>Study Plan</a> also the extra services that you provide.
    Tons of words cannot express my gratitude toward you and your team!`,
  },
  {
    id: "cdmp-study-plan-012",
    topic: "Data Strategy",
    name: "Ryan Balazs",
    img: Balazs,
    title: "Data Architect",
    short: "Just wanted to say thank you... I found it quite useful",
    content: `Just wanted to say thank you for providing the <a href='https://www.datastrategypros.com/products/cdmp-study-plan' target='_blank' rel='noreferrer'>CDMP Study Plan</a> and helping to build a community of practice around it.
    I found it quite useful in preparing for and passing my <a href='https://www.datastrategypros.com/products/cdmp-fundamentals-notes' target='_blank' rel='noreferrer'>CDMP Fundamentals Exam</a>.`,
  },
  {
    id: "cdmp-study-plan-013",
    topic: "Data Strategy",
    name: "Jeff Johnson",
    img: Johnson,
    title: "Director of Data Governance",
    short: "Study Plan is great and keeps content top of mind",
    content: `I took the CDMP fundamentals exam yesterday and passed with a score of 87. Thought I would share some of my experiences leading up to this.<br><br>
    "I started the  <a href='https://www.datastrategypros.com/products/cdmp-study-plan' target='_blank' rel='noreferrer'>CDMP Study Plan</a> and
    read approx 1-2 chapters a week and did the CDMP practice test every week. For answers where I was uncertain or got them wrong,
    I copied them into a notes file. I also copied the contents of each of the emails I received in the Study Plan into that same doc.
    I typically reviewed that notes file before each practice exam.<br><br>
    "All in all, the Study Plan is great and keeps content top of mind.`,
  },
  {
    id: "cdmp-study-plan-014",
    topic: "Data Strategy",
    name: "Dan Affen",
    img: Affen,
    title: "Data Management Consultant",
    short: "A pivotal moment was the CDMP Study Plan",
    content: `I invested time in reading the <a href='https://amzn.to/32oK8hH' target='_blank' rel='noreferrer'><em>DMBOK</em></a>,
    reading it from cover to cover over 4 months.
    A pivotal moment was the <a href='https://www.datastrategypros.com/products/cdmp-study-plan' target='_blank' rel='noreferrer'>CDMP Study Plan</a> crafted by Nicole Janeway Bills. 
    Her meticulously detailed plan, featuring interview questions and external resources, proved instrumental in my preparation.`,
    plainText: `I invested time in reading the <a href='https://amzn.to/32oK8hH' target='_blank' rel='noreferrer'><em>DMBOK</em></a>, reading it from cover to cover over 4 months.
    A pivotal moment was the CDMP Study Plan crafted by Nicole Janeway Bills. Her meticulously detailed plan, featuring interview questions and external resources, proved instrumental in my preparation.`,
  },
  {
    id: "cdmp-study-plan-015",
    topic: "Data Strategy",
    name: "Rob Egelink",
    title: "Senior Data Specialist",
    short: "90 days was not too ambitious",
    content: `Last year, I purchased the <a href='https://www.datastrategypros.com/products/cdmp-study-plan' target='_blank' rel='noreferrer'>90 day Study Plan</a>, and I thought 90 days was too ambitious. But it was not, after I gave myself a deadline by booking the exam, it was just fine... I passed the CDMP Fundamentals Exam with a score of 86%.`,
    plainText: `Last year, I purchased the 90 day Study Plan, and I thought 90 days was too ambitious. But it was not, after I gave myself a deadline by booking the exam, it was just fine... I passed the CDMP Fundamentals Exam with a score of 86%.`,
  },
  {
    id: "cdmp-study-plan-016",
    topic: "Data Strategy",
    name: "Nupur Gandhi",
    img: Gandhi,
    title: "Senior Business Data Consultant",
    short: "Breaks it down into small steps",
    content: `The email <a href='https://www.datastrategypros.com/products/cdmp-study-plan' target='_blank' rel='noreferrer'>Study Plan</a>
    sent over the course of 90 days was a great additional support
    that I needed. Like, it literally told me, this is what you should read.
    We all know the <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
    <em>DMBOK</em></a> such a huge body of knowledge, and if you are trying to take the exam,
    you really need to break it down into small steps that you can digest.
    I would highly urge... you... to avail her <a href='https://www.datastrategypros.com/products/cdmp-study-plan' target='_blank' rel='noreferrer'>Study Plan</a>,
    which is so helpful.`,
  },
  {
    id: "cdmp-study-plan-017",
    topic: "Data Strategy",
    name: "Jeff Johnson",
    img: Johnson,
    title: "Director of Data Governance",
    short: "Study Plan is great and keeps content top of mind",
    content: `I took the CDMP fundamentals exam yesterday and passed with a score of 87.
    I started the  <a href='https://www.datastrategypros.com/products/cdmp-study-plan' target='_blank' rel='noreferrer'>CDMP Study Plan</a> and
    read approx 1-2 chapters a week...
    All in all, the Study Plan is great and keeps content top of mind.`,
  },
  {
    id: "cdmp-study-plan-018",
    topic: "Data Strategy",
    name: "Iyad Kloub",
    img: Kloub,
    title: "Senior Client Engineer",
    short: "Helped me keep my motivation",
    content: `Preparing for the exam can be a daunting task due to the sheer volume of the
    <a href='http://amzn.to/32oK8hH' target='_blank' rel='noreferrer'><em>DMBOK</em></a>.
    This is why I highly recommend taking a look at the
    <a href='https://www.datastrategypros.com/products/cdmp-study-plan' target='_blank' rel='noreferrer'>Study Plan</a>,
    great tips, and short summaries offered by Data Strategy Professionals.
    They certainly helped me keep my motivation during busy work periods
    when I felt like giving up.`,
    plainText: `Preparing for the exam can be a daunting task due to the sheer volume of the DMBOK. This is why I highly recommend taking a look at the Study Plan, great tips, and short summaries offered by Data Strategy Professionals. They certainly helped me keep my motivation during busy work periods when I felt like giving up.`,
  },
  {
    id: "cdmp-study-plan-019",
    topic: "Data Strategy",
    name: "Teri Hinds",
    img: Hinds,
    title: "Principal Consultant",
    short: "CDMP prep materials were excellent!",
    content: `I want to thank you for the CDMP prep materials and courses you offer — I used them when I took the CDMP a year and a half ago and they were excellent!`,
    plainText: `I want to thank you for the CDMP prep materials and courses you offer — I used them when I took the CDMP a year and a half ago and they were excellent!`,
  },
  {
    id: "cdmp-study-plan-020",
    topic: "Data Strategy",
    name: "Nupur Gandhi",
    img: Gandhi,
    title: "Senior Business Data Consultant",
    short: "Breaks it down into small steps",
    content: `The email <a href='https://www.datastrategypros.com/products/cdmp-study-plan' target='_blank' rel='noreferrer'>Study Plan</a>
    sent over the course of 90 days was a great additional support
    that I needed. Like, it literally told me, this is what you should read.
    We all know the <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
    <em>DMBOK</em></a> such a huge body of knowledge, and if you are trying to take the exam,
    you really need to break it down into small steps that you can digest.
    [Data Strategy Professionals] has taken the pain to do all of that for all of you.`,
  },
  {
    id: "cdmp-study-plan-021",
    topic: "Data Strategy",
    name: "Michael Edwards",
    img: Edwards,
    title: "Senior Data Modeler",
    short: "A lot of thought has gone into it",
    content: `
    I can tell a lot of thought has gone into the <a href='https://www.datastrategypros.com/products/cdmp-study-plan' target='_blank' rel='noreferrer'>CDMP Study Plan</a> emails.  No fluff, I think it strikes the right balance.<br/><br/>
    "But at the end of the day, you have to want it. I am sure many of us have various reasons why we want the
    <a href='https://www.datastrategypros.com/resources/cdmp-fundamentals-exam' target='_blank' rel='noreferrer'>CDMP</a>.
    Don’t sell yourself short, do the reading and take notes to reinforce that reading. There is a lot there.<br/><br/>
    "One of the things I am doing is to understand at a high level each of the 11 components of the Framework.
    I made a poster of the wheel and posted it in my office. 
    The additional articles linked in the <a href='https://www.datastrategypros.com/products/cdmp-study-plan' target='_blank' rel='noreferrer'>Study Plan</a>
    have been helpful in giving me a break from the <a href="http://amzn.to/32oK8hH"><em>DMBOK</em></a> but still allow me to keep it in focus.`,
    plainText: `I can tell a lot of thought has gone into the CDMP Study Plan emails.  No fluff, I think it strikes the right balance.<br/><br/>"But at the end of the day, you have to want it. I am sure many of us have various reasons why we want the CDMP certification. Don’t sell yourself short, do the reading and take notes to reinforce that reading. There is a lot there.<br/><br/>"One of the things I am doing is to understand at a high level each of the 11 components of the Framework. I made a poster of the wheel and posted it in my office. The additional articles linked in the Study Plan have been helpful in giving me a break from the DMBOK but still allow me to keep it in focus.`,
  },
  {
    id: "cdmp-fundamentals-notes-000",
    topic: "Data Strategy",
    name: "Darin Zinnecker",
    title: "Manager, Enterprise Data Solutions",
    short: `I got a tremendous amount of value`,
    content: `I recently passed the CDMP exam at the Master level...
    First off, I wanted to let you know that I got a tremendous amount of value from the
    <a href='https://www.datastrategypros.com/products/cdmp-question-sets' target='_blank' rel='noreferrer'>Question Sets</a> and
    <a href='https://www.datastrategypros.com/products/cdmp-fundamentals-notes' target='_blank' rel='noreferrer'>Fundamentals Notes</a>.
    I went through both sets of materials many times, and it really helped me to better understand the content,
    focus my notes and highlights in the <a href='https://amzn.to/32oK8hH' target='_blank' rel='noreferrer'><em>DMBOK</em></a>,
    and prepare for the types of questions I saw on the exam.`,
  },
  {
    id: "cdmp-fundamentals-notes-001",
    topic: "Data Strategy",
    name: "Tony Virella, Jr.",
    img: Virella,
    title: "Senior Data Analyst",
    short: "Very easy to read, well structured, and approachable",
    content: `The overall content of the <a href='https://www.datastrategypros.com/products/cdmp-fundamentals-notes' target='_blank' rel='noreferrer'>
      CDMP Fundamentals Notes</a> is very easy to read, well structured, and approachable.
      I would highly recommend this guide as a compliment to your study material.`,
  },
  {
    id: "cdmp-fundamentals-notes-002",
    topic: "Data Strategy",
    name: "Tony Virella, Jr.",
    img: Virella,
    title: "Senior Data Analyst",
    short: "Very easy to read, well structured, and approachable",
    content: `The overall content of the <a href='https://www.datastrategypros.com/products/cdmp-fundamentals-notes' target='_blank' rel='noreferrer'>
    CDMP Fundamentals Notes</a> is very easy to read, well structured, and approachable.
    I would highly recommend this guide as a compliment to your study material.<br/><br/>"The study guide covers each chapter of the
    <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer"><em>DMBOK</em></a>.
    It starts each section with a brief summary of that chapter followed by notes of key points that are
    on the exam. You can also refer to the <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
    <em>DMBOK</em>
    </a> for greater detail.<br/><br/>"The overall content is very
    easy to read, well structured, and approachable. I would highly recommend this guide as a compliment to
    your study material.`,
  },
  {
    id: "cdmp-fundamentals-notes-003",
    topic: "Data Strategy",
    name: "Andrea Wehrung",
    img: Wehrung,
    title: "Director, Data Strategy and Governance",
    short: "Well organized and clearly call-out the key concepts",
    content: `Data Strategy Pro's <a href='https://www.datastrategypros.com/products/cdmp-fundamentals-notes' target='_blank' rel='noreferrer'>CDMP Fundamentals Notes</a> are well organized and clearly call-out the key concepts in
    each chapter. They will be my first go-to for quick reference for the CDMP!`,
  },
  {
    id: "cdmp-fundamentals-notes-004",
    topic: "Data Strategy",
    name: "Rutvik Ahire",
    title: "Information Technology Consultant",
    img: Rutvik,
    short: "Helped me appear for the exam with confidence",
    content: `I have successfully cleared the <a href='https://www.datastrategypros.com/resources/cdmp-fundamentals-exam' target='_blank' rel='noreferrer'>CDMP Fundamentals Exam</a>.
    I wanted to thank you for the resources you provided, especially the <a href='https://www.datastrategypros.com/products/cdmp-fundamentals-notes' target='_blank' rel='noreferrer'>CDMP Fundamentals Notes</a>, which helped me appear for the exam with confidence.`,
  },
  {
    id: "cdmp-question-sets-000",
    topic: "Data Strategy",
    name: "Marc Knüttel",
    img: Knuttel,
    title: "Senior Manager of Enterprise Data Architecture",
    short: `Thank you for helping out the community in the right way`,
    content: `"I was looking for something beyond the CDMP questions provided by DAMA to prepare for the exam. I knew I
    had the experience, but I didn't want to retake. The prep questions on Udemy are basically useless.
    The purpose of the Data Strategy Professionals <strong>Question Sets</strong> and
    <a href='https://www.datastrategypros.com/products/cdmp-fundamentals-notes' target='_blank' rel='noreferrer'>
      Notes
    </a>
    is really to help someone. Your heart was in the right place when creating this. Thank you for helping out
    the community in the right way.
    Data Strategy Professionals offers preparation in a very practical, in a very straightforward way,
    cutting out all the noise to get people ready for a proper career in this area."`,
  },
  {
    id: "cdmp-question-sets-001",
    topic: "Data Strategy",
    name: "Marc Knüttel",
    img: Knuttel,
    title: "Senior Manager of Enterprise Data Architecture",
    short: "Thank you for helping out the community in the right way",
    content: `The purpose of the Data Strategy Professionals <a href='https://www.datastrategypros.com/products/cdmp-question-sets' target='_blank' rel='noreferrer'>
      Question Sets</a> and <a href='https://www.datastrategypros.com/products/cdmp-fundamentals-notes' target='_blank' rel='noreferrer'>Notes</a> 
      is really to help someone. Your heart was in the right place when creating this. Thank you for helping out the community in the right way.`,
  },
  {
    id: "cdmp-question-sets-002",
    topic: "general",
    name: "Kevin McBeth",
    img: McBeth,
    title: "MLOps Engineer",
    short: "Learning happens from repeatedly being tested",
    content: `Learning happens from repeatedly being tested on the material. If you want to study for a test, the
      absolute best way to learn to pass the test is to take a lot of tests that are similar to it and get
      accurate feedback about why you were wrong.`,
  },
  {
    id: "cdmp-question-sets-003",
    topic: "Data Strategy",
    name: "Adetola Kolawole",
    img: Kolawole,
    title: "Data Governance and Quality Professional",
    short: "Really useful and insightful",
    content: `I find the <a href='https://www.datastrategypros.com/products/cdmp-question-sets' target='_blank' rel='noreferrer'>CDMP Question Sets</a> really useful and insightful (especially the reference to page numbers of each answer).
      And the <a href='https://www.youtube.com/playlist?list=PLGd_Hl6NWLZe1Jtz8oG0ZJ7lDzdgOGZn5' target='_blank' rel='noreferrer'>YouTube pomodoro sessions</a> 
      — the discussions help simplify the vocabulary in the 
      <a href='https://amzn.to/32oK8hH' target='_blank' rel='noreferrer'><em>DMBOK</em></a>.`,
  },
  {
    id: "cdmp-question-sets-004",
    topic: "Data Strategy",
    name: "Brenda Peick Hanson",
    img: Hanson,
    title: "Senior Manager of Enterprise Data Architecture",
    short: "As helpful or more helpful than the [official] questions",
    content: `These <a href='https://www.datastrategypros.com/products/cdmp-question-sets' target='_blank' rel='noreferrer'>questions</a>
      were as helpful or more helpful than the sample questions from the official DAMA test bank.`,
    plainText:
      "These questions were as helpful or more helpful than the sample questions from the official DAMA test bank.",
  },
  {
    id: "cdmp-question-sets-005",
    topic: "Data Strategy",
    name: "Karen Grapes",
    title: "Information Management Consultant",
    img: Grapes,
    short: "Helped me in my studies",
    content: `I am DAMA certified now. I took the exam in October, and the
    <a href='https://www.datastrategypros.com/products/cdmp-question-sets' target='_blank' rel='noreferrer'>practice exam questions</a> helped me in my studies.`,
    plainText:
      "I am DAMA certified now. I took the exam in October, and the practice exam questions helped me in my studies.",
  },
  {
    id: "cdmp-question-sets-006",
    topic: "Data Strategy",
    name: "Vera Bagboudarian",
    img: Bagboudarian,
    title: "Data Project Manager",
    short: "More challenging than the DAMA practice exams",
    content: `For anyone starting to prepare for the exam, I will advise them to use your 
    <a href='https://www.datastrategypros.com/products/cdmp-question-sets' target='_blank' rel='noreferrer'>Question Sets</a>,
    as they are more challenging than the DAMA practice exams. And they are a great learning tool.`,
    plainText: `For anyone starting to prepare for the exam, I will advise them to use your question sets, as they are more challenging than the DAMA practice exams. And they are a great learning tool.`,
  },
  {
    id: "cdmp-question-sets-007",
    topic: "Data Strategy",
    name: "Raja Amad Iftikhar",
    img: Iftikhar,
    title: "Data Analyst",
    short: "Helped me a lot in my preparation",
    content: `I passed the exam. I utilized your <a href='https://www.datastrategypros.com/products/cdmp-question-sets' target='_blank' rel='noreferrer'>exam questions</a> from your website. The helped me a lot in my preparation.`,
    plainText: `I passed the exam. I utilized your exam questions from your website. The helped me a lot in my preparation.`,
  },
  {
    id: "cdmp-question-sets-008",
    topic: "Data Strategy",
    name: "Gopi Palamadai",
    img: Palamadai,
    title: "Data Architect",
    short: "Very helpful to hone the skills before the exams",
    content: `I completed Data Management Fundamentals at Master level with 91 percent. I used your 3 <a href='https://www.datastrategypros.com/products/cdmp-question-sets' target='_blank' rel='noreferrer'>Question Sets</a>
    practice exams and it was very helpful to hone the skills before the exams.`,
    plainText: `I completed Data Management Fundamentals at Master level with 91 percent. I used your 3 Question Sets practice exams and it was very helpful to hone the skills before the exams.`,
  },
  {
    id: "cdmp-question-sets-009",
    topic: "Data Strategy",
    name: "Rob Egelink",
    title: "Senior Data Specialist",
    short: "Your practice exams have been a tremendous help",
    content:
      "I passed the CDMP Fundamentals Exam with a score of 86%. Your <a href='https://www.datastrategypros.com/products/cdmp-question-sets' target='_blank' rel='noreferrer'>practice exams</a> have been a tremendous help.",
    plainText:
      "I passed the CDMP Fundamentals Exam with a score of 86%. Your practice exams have been a tremendous help.",
  },
  {
    id: "cdmp-question-sets-010",
    topic: "Data Strategy",
    name: "Ali Fiaz",
    img: Fiaz,
    title: "Senior Data Scientist",
    short: "Very valuable resources in my preparation",
    content:
      "The <a href='https://www.datastrategypros.com/products/cdmp-question-sets' target='_blank' rel='noreferrer'>CDMP Question Set</a> that I purchased, your <a href='https://www.youtube.com/playlist?list=PLGd_Hl6NWLZe1Jtz8oG0ZJ7lDzdgOGZn5'  target='_blank' rel='noreferrer'>YouTube CDMP Study Session playlist</a> as well as <a href='https://www.linkedin.com/groups/12965811/'  target='_blank' rel='noreferrer'>LinkedIn tips</a> were very valuable resources in my preparation. I'm pleased to inform that I have passed the exam at Practitioner level.",
    plainText:
      "The CDMP Question Set that I purchased, your YouTube CDMP Study Session playlist as well as LinkedIn tips were very valuable resources in my preparation. I'm pleased to inform that I have passed the exam at Practitioner level.",
  },
  {
    id: "cdmp-question-sets-011",
    topic: "Data Strategy",
    name: "Rajesh Sripelly",
    img: Sripelly,
    title: "Information Management Specialist",
    short: "Helped me a lot",
    content: `I passed the <a href='https://www.datastrategypros.com/resources/cdmp-fundamentals-exam' target='_blank' rel='noreferrer'>CDMP exam</a> with 75% score. Thanks for the guidance and <a href='https://www.datastrategypros.com/products/cdmp-question-sets' target='_blank' rel='noreferrer'>CDMP Question Sets</a>. These helped me a lot for checking my preparation levels before going to exam.`,
    plainText: `I passed the CDMP exam with 75% score. Thanks for the guidance and CDMP Question Sets. These helped me a lot for checking my preparation levels before going to exam.`,
  },
  {
    id: "cdmp-question-sets-012",
    topic: "Data Strategy",
    name: "Ali Alperen Çolak",
    img: Colak,
    title: "Tech Lead Manager",
    short: "CDMP practice questions were incredibly helpful!",
    content: `The <a href='https://www.datastrategypros.com/products/cdmp-question-sets' target='_blank' rel='noreferrer'>CDMP practice questions</a> provided by Data Strategy Professionals were incredibly helpful in my exam preparation. They made a big difference!`,
    plainText: `The CDMP practice questions provided by Data Strategy Professionals were incredibly helpful in my exam preparation. They made a big difference!`,
  },
  {
    id: "cdmp-question-sets-013",
    topic: "Data Strategy",
    name: "Zohaib Aref",
    img: Aref,
    title: "Data Quality Analyst",
    short: "Really helpful",
    content: `The <a href="/products/cdmp-question-sets" target="_blank" rel='noreferrer'>Question Sets</a> were really helpful. This helped me identify where my weak points were.`,
    plainText: `The Question Sets were really helpful. This helped me identify where my weak points were.`,
  },
  {
    id: "cdmp-question-sets-014",
    topic: "Data Strategy",
    name: "Krupesh Desai",
    img: Desai,
    title: "Data Governance Technical Specialist",
    short: "The Question Sets helped me find gaps in my knowledge.",
    content: `I watched all your <a href='https://www.youtube.com/@datastrategypros' target='_blank' rel='noreferrer'>YouTube videos</a> to revise each of the key chapters.  Your three <a href='https://www.datastrategypros.com/products/cdmp-question-sets' target='_blank' rel='noreferrer'>Question Sets</a> then helped me to further find gaps in my knowledge.  I am glad to get an 88% in the CDMP exam.  What awesome stuff you are doing.`,
    plainText: `I watched all your YouTube videos to revise each of the key chapters.  Your three Question Sets then helped me to further find gaps in my knowledge.  I am glad to get an 88% in the CDMP exam.  What awesome stuff you are doing.`,
  },
  {
    id: "cdmp-exam-readiness-check-000",
    topic: "Data Strategy",
    name: "Vera Bagboudarian",
    img: Bagboudarian,
    title: "Data Project Manager",
    short: "A very valuable tool for me",
    content: `The <a href='https://www.datastrategypros.com/products/cdmp-exam-readiness-check' target='_blank' rel='noreferrer'>CDMP Exam Readiness Check</a> was a very valuable tool for me. I particularly liked the mock exam since it forced me to go back, re-read, and mark some paragraphs in the <em>DMBOK</em> that I didn't remember reading.`,
    plainText: `The CDMP Exam Readiness Check was a very valuable tool for me. I particularly liked the mock exam since it forced me to go back, re-read, and mark some paragraphs in the <a href='https://amzn.to/32oK8hH' target='_blank' rel='noreferrer'><em>DMBOK</em></a> that I didn't remember reading.`,
  },
  {
    id: "cdmp-fundamentals-bundle-000",
    topic: "Data Strategy",
    name: "Fares Braham",
    img: Braham,
    title: "Lead Data Analyst",
    short: "I recommend everyone to follow your study program",
    content:
      "I obtained [the CDMP] thanks to the high quality of your  <a href='https://www.datastrategypros.com/products/cdmp-fundamentals-bundle' target='_blank' rel='noreferrer'>study program</a>! I recommend everyone to follow it. Well done 👏💯👏",
    plainText:
      "`I obtained [the CDMP] thanks to the high quality of your study program! I recommend everyone to follow it. Well done 👏💯👏",
  },
  {
    id: "cdmp-fundamentals-bundle-001",
    topic: "Data Strategy",
    name: "Svetlana Khimina",
    img: Khimina,
    title: "Data-Driven Strategy and Analytics",
    short: "Extremely useful",
    content:
      "I've purchased the  <a href='https://www.datastrategypros.com/products/cdmp-fundamentals-bundle' target='_blank' rel='noreferrer'>CDMP Bundle</a>, and I find all the materials extremely useful.",
    plainText: "I've purchased the CDMP Bundle, and I find all the materials extremely useful.",
  },
  {
    id: "cdmp-fundamentals-bundle-002",
    topic: "Data Strategy",
    name: "Fares Braham",
    img: Braham,
    title: "Lead Data Analyst",
    short: "I recommend everyone to follow your study program",
    content:
      "I obtained [the CDMP] thanks to the high quality of your  <a href='https://www.datastrategypros.com/products/cdmp-fundamentals-bundle' target='_blank' rel='noreferrer'>study program</a>! I recommend everyone to follow it. Well done.",
    plainText:
      "`I obtained [the CDMP] thanks to the high quality of your study program! I recommend everyone to follow it. Well done.",
  },
  {
    id: `cdmp-fundamentals-bundle-003`,
    topic: "Data Strategy",
    name: `Jonas Willems`,
    img: Willems,
    title: "Data & Analytics",
    short: "Truly paid off",
    content: `The <a href='https://www.datastrategypros.com/products/cdmp-fundamentals-notes' target='_blank' rel='noreferrer'>Fundamentals Notes</a>, <a href='https://www.datastrategypros.com/products/cdmp-question-sets' target='_blank' rel='noreferrer'>practice questions</a>, and <a href='https://www.datastrategypros.com/events' target='_blank' rel='noreferrer'>Discussion Groups</a> truly paid off.`,
    plainText: `The Fundamentals Notes, practice questions, and Discussion Groups truly paid off.`,
  },
  {
    id: "specialist-exam-guides-000",
    topic: "Data Strategy",
    name: "Md Nasim Akhtar",
    title: "Data Governance Manager",
    short: "Good quality",
    content:
      "I really like your <a href='https://www.datastrategypros.com/products/specialist-guides' target='_blank' rel='noreferrer'>Specialist Guides</a>, good quality. I recommended your website to a bunch of my friends at Citi Bank. They too are delighted.",
    plainText:
      "I really like your Specialist Guides, good quality. I recommended your website to a bunch of my friends at Citi Bank. They too are delighted.",
  },
  {
    id: "specialist-exam-guides-001",
    topic: "Data Strategy",
    name: "Albert Chiwara",
    img: Albert,
    short: "An excellent resource",
    title: "Manager — Business Process Audit & Advisory",
    content: `The <a href='https://www.datastrategypros.com/products/specialist-guides' target='_blank' rel='noreferrer'>Specialist Guides</a>
    are an excellent resource for anyone preparing for the
    <a href='https://www.datastrategypros.com/resources/cdmp-specialist-exam' target='_blank' rel='noreferrer'>Specialist Exams</a>.
    Authored by a renowned educator in the data management field, these guides offer an expansive yet concise overview of the knowledge areas.
    What stood out to me was the clarity with which complex concepts are explained.
    I highly recommend these guides to anyone looking to deepen their understanding and navigate the specialist exams.`,
    plainText: `The Specialist Guides are an excellent resource for anyone preparing for the Specialist Exams.
    Authored by a renowned educator in the data management field, these guides offer an expansive yet concise overview of the knowledge areas.
    What stood out to me was the clarity with which complex concepts are explained.
    I highly recommend these guides to anyone looking to deepen their understanding and navigate the specialist exams.`,
  },
  {
    id: "specialist-exam-guides-002",
    topic: "Data Strategy",
    name: "Ryan Balazs",
    img: Balazs,
    title: "Data Architect",
    short: "Used as a reference",
    content: `My approach for the Specialist Exams was:
    select topics that aligned with current or past work experience;
    took the Specialist Exams shortly after the first exam (many questions for the Specialist Exams were sourced from the Fundamentals Exam);
    used your <a href='https://www.datastrategypros.com/products/specialist-guides' target='_blank' rel='noreferrer'>Study Guides</a> and <a href='https://www.datastrategypros.com/products/specialist-question-sets' target='_blank' rel='noreferrer'>practice exams</a> as reference;
    studied, studied, studied.`,
    plainText: `My approach for the Specialist Exams was:
    select topics that aligned with current or past work experience;
    took the Specialist Exams shortly after the first exam (many questions for the Specialist Exams were sourced from the Fundamentals Exam);
    used your Study Guides and  practice exams as reference;
    studied, studied, studied;
    carved out time each week to prep for the exam`,
  },
  {
    id: "specialist-question-sets-000",
    topic: "Data Strategy",
    name: "Albert Chiwara",
    img: Albert,
    title: "Manager — Business Process Audit & Advisory",
    short: "Helped me test my knowledge",
    content: `I'm happy with the <a href='https://www.datastrategypros.com/products/specialist-question-sets' target='_blank' rel='noreferrer'>Specialist Question Sets</a>, and I have found them very helpful. Having access to these practice questions has helped me test my knowledge during my exam preparation`,
    plainText: `I'm happy with the Specialist Question Sets, and I have found them very helpful. Having access to these practice questions has helped me test my knowledge during my exam preparation.`,
  },
  {
    id: "bids-email-bootcamp-001",
    topic: "Data Science",
    name: "Ken Foo",
    img: Foo,
    title: "Data Scientist",
    short: "Informative, insightful, and organized",

    content: `The <a href='https://www.datastrategypros.com/products/data-science-email-bootcamp' target='_blank' rel='noreferrer'>
      Data Science Email Bootcamp</a> is not only informative – it's insightful and organized.
      More Data Science content should be this entertaining. 
      This kind of thing could help prevent someone from giving up or becoming disinterested because they think Machine Learning
      is super complex or Python is a pain to learn. It's not!`,
    plainText: `The Data Science Email Bootcamp is not only informative – it's insightful and organized.
      More Data Science content should be this entertaining. 
      This kind of thing could help prevent someone from giving up or becoming disinterested because they think Machine Learning
      is super complex or Python is a pain to learn. It's not!`,
  },
  {
    id: "bids-email-bootcamp-002",
    topic: "Data Science",
    name: "Dominic Lovell",
    img: Lovell,
    title: "Data Infrastructure Analyst",
    short: "A great way to build Data Science knowledge over time",
    content:
      "We're working and learning from home on a daily basis right now, which can be overwhelming for some, but having a daily reminder, which is an interesting read and helpful cheat sheet, can be truly rewarding. I look forward to the <a href='https://www.datastrategypros.com/products/data-science-email-bootcamp' target='_blank' rel='noreferrer'>Breaking into Data Science</a> emails. Even if your schedule is hectic, the Email Bootcamp is an easy way to build Data Science knowledge over time.",
    plainText:
      "We're working and learning from home on a daily basis right now, which can be overwhelming for some, but having a daily reminder, which is an interesting read and helpful cheat sheet, can be truly rewarding. I look forward to the Breaking into Data Science emails. Even if your schedule is hectic, the Email Bootcamp is an easy way to build Data Science knowledge over time.",
  },
  {
    id: "bids-email-bootcamp-003",
    topic: "Data Science",
    name: "Irina Pavlovna",
    title: "Data Analyst",
    short: "Really useful links",
    content:
      "Thanks for your <a href='https://www.datastrategypros.com/products/data-science-email-bootcamp' target='_blank' rel='noreferrer'>email bootcamp</a>! Really useful links.",
    plainText: "Thanks for your email bootcamp! Really useful links.",
  },
  {
    id: "cap-bundle-000",
    topic: "Data Analytics",
    name: "Visanu Chumongkhon",
    img: Chumongkhon,
    title: "Business Insights & Analytics",
    content:
      "I appreciate the materials from Data Strategy Professionals that helped me prep for the <a href='https://www.datastrategypros.com/resources/certified-analytics-professional' target='_blank' rel='noreferrer'>CAP INFORMS exam</a>.",
    plainText:
      "I appreciate the materials from Data Strategy Professionals that helped me prep for the CAP INFORMS exam.",
  },
  {
    id: "cap-exam-questions-000",
    topic: "Data Analytics",
    name: "Kevin McBeth",
    img: McBeth,
    title: "MLOps Engineer",
    short: "Incredibly useful",
    content: `The practice tests are incredibly useful. I've been using the <a href='https://www.datastrategypros.com/products/cap-exam-questions' target='_blank' rel='noreferrer'>
    Certified Analytics Professional (CAP) Practice Questions</a> over the past two weeks and Googling the things I don't know. I've found a new algorithm to solve a problem at work we've been struggling with for a few months,
    been better able to use IT's language in asking for desired database changes, and found that my boss's boss can understand the language I've learned
    and finally approve some of the things we want to try! Definitely recommend using it as a basis for studying for improving job performance
    (which I hope will lead to $$$).`,
    plainText: `The practice tests are incredibly useful. I've been using the 
    Certified Analytics Professional (CAP) Practice Questions over the past two weeks and Googling the things I don't know.  I've found a new algorithm to solve a problem at work we've been struggling with for a few months,
    been better able to use IT's language in asking for desired database changes, and found that my boss's boss can understand the language I've learned
    and finally approve some of the things we want to try! Definitely recommend using it as a basis for studying for improving job performance
    (which I hope will lead to $$$).`,
  },
  {
    id: "cap-exam-questions-001",
    topic: "Data Analytics",
    name: "Kevin McBeth",
    img: McBeth,
    title: "MLOps Engineer",
    short: "Incredibly useful",
    content: `The <a href='https://www.datastrategypros.com/products/cap-exam-questions' target='_blank' rel='noreferrer'>practice tests</a>
    are incredibly useful.   Definitely recommend using it as a basis for studying for improving job performance
    (which I hope will lead to $$$).`,
    plainText: `The practice tests are incredibly useful.
    Definitely recommend using it as a basis for studying for improving job performance
    (which I hope will lead to $$$).`,
  },
  {
    id: "cap-study-plan-000",
    topic: "Data Analytics",
    name: "Andrew Paul Acosta",
    title: "Data Scientist",
    img: Andrew,
    short: "A daily delight, a step-by-step tutorial to taking the exam",
    content: `The <a href='https://www.datastrategypros.com/products/cap-study-plan' target='_blank' rel='noreferrer'>CAP Study Plan</a>
    is a daily delight, a step-by-step tutorial to taking the exam. In daily emails over 65 days, the instructions are clear,
    and there are links to supplement new definitions and concepts. I recommend this study plan for anyone taking the
    <a href='https://www.datastrategypros.com/resources/certified-analytics-professional' target='_blank' rel='noreferrer'>Certified Analytics Professional (CAP) exam</a>.`,
    plainText: `The CAP Study Plan is a daily delight, a step-by-step tutorial to taking the exam. In daily emails over 65 days, the instructions are clear,
    and there are links to supplement new definitions and concepts. I recommend this study plan for anyone taking the
    Certified Analytics Professional (CAP) exam.`,
  },
  {
    id: "cippe-study-plan-000",
    topic: "Data Privacy",
    name: "Djiwandou Putro",
    img: Putro,
    title: "Engineering Manager",
    content: `I think the <a href='https://www.datastrategypros.com/products/cippe-study-plan' target='_blank' rel='noreferrer'>CIPP/E Study Plan</a> is great.  
    Currently I'm also using it as the introduction/preparation for me and my team to go through data security interview with Indonesian Ministry of Health.
    It's really useful 🤩`,
  },
  {
    id: "cippe-study-plan-001",
    topic: "Data Privacy",
    name: "Amit Sharma",
    img: Sharma,
    title: "Data Product Owner",
    short: "One of the best investments I have made in my professional development",
    content: `When I decided to pursue the CIPP/E certification, I knew I needed a comprehensive and reliable resource to help me succeed,
  and the <a href='https://www.datastrategypros.com/products/cippe-study-plan' target='_blank' rel='noreferrer'>CIPP/E Study Plan</a>
  surpassed all my expectations. The content is exceptionally well-structured, covering all the domains of the CIPP/E exam in great detail. 
  The study materials are presented in a clear and concise manner in the form of daily emails, making even the most complex GDPR topics easy to grasp.
  I wholeheartedly recommend this course to anyone aspiring to become a Certified Information Privacy Professional.
  It is undoubtedly one of the best investments I have made in my professional development. Thank you for an outstanding learning experience!`,
    plainText: `When I decided to pursue the CIPP/E certification, I knew I needed a comprehensive and reliable resource to help me succeed,
  and the CIPP/E Study Plan surpassed all my expectations. The content is exceptionally well-structured,
  covering all the domains of the CIPP/E exam in great detail. The study materials are presented in a clear and concise manner in the form of daily emails, making even the most complex GDPR topics easy to grasp.
  I wholeheartedly recommend this course to anyone aspiring to become a Certified Information Privacy Professional.
  It is undoubtedly one of the best investments I have made in my professional development. Thank you for an outstanding learning experience!`,
  },
  {
    id: "cippe-study-plan-002",
    topic: "Data Privacy",
    name: "Amit Sharma",
    img: Sharma,
    title: "Data Product Owner",
    short: "One of the best investments I have made in my professional development",
    content: `When I decided to pursue the CIPP/E certification, I knew I needed a comprehensive and reliable resource to help me succeed,
    and the <a href='https://www.datastrategypros.com/products/cippe-study-plan' target='_blank' rel='noreferrer'>CIPP/E Study Plan</a> surpassed all my expectations. 
    I wholeheartedly recommend this course to anyone aspiring to become a Certified Information Privacy Professional.
    It is undoubtedly one of the best investments I have made in my professional development. Thank you for an outstanding learning experience!`,
    plainText: `When I decided to pursue the CIPP/E certification, I knew I needed a comprehensive and reliable resource to help me succeed,
  and the CIPP/E Study Plan surpassed all my expectations. 
  I wholeheartedly recommend this course to anyone aspiring to become a Certified Information Privacy Professional.
  It is undoubtedly one of the best investments I have made in my professional development. Thank you for an outstanding learning experience!`,
  },
  {
    id: "resume-review-000",
    topic: "Career Services",
    name: "Ariadne Latorre",
    img: Latorre,
    title: "Attorney at Law",
    short: "Very pleased",
    content: `I am very pleased with the <a href='https://www.datastrategypros.com/products/resume-review' target='_blank' rel='noreferrer'>Resume & LinkedIn Review</a> performed by Nicole.
    Her observations and suggestions were very valuable and on point. Nicole responded to all my questions and performed a second review after I had incorporated the suggested changes.
    I now feel very confident about my resume in terms of completeness while keeping it concise.`,
  },
  {
    id: "resume-review-001",
    topic: "Career Services",
    name: "Edward Mortimer-Tsushima",
    img: Mortimer,
    title: "Software Engineer",
    short: "Feeling much more confident",
    content: `Nicole did a fabulous job of
    <a href='https://www.datastrategypros.com/products/resume-review' target='_blank' rel='noreferrer'>reviewing my resume</a>. Before working with Data Strategy Professionals,
    I was having trouble getting recruiters to take me seriously, but after DSP helped reformat and reorganize my materials,
    I am feeling much more confident, and the responses have improved notably.`,
    plainText: `Nicole did a fabulous job of reviewing my resume. Before working with Data Strategy Professionals,
    I was having trouble getting recruiters to take me seriously, but after DSP helped reformat and reorganize my materials,
    I am feeling much more confident, and the responses have improved notably.`,
  },
  {
    id: "career-coaching-000",
    topic: "Career Services",
    name: "Craig March",
    img: March,
    title: "Senior Data Business Partner",
    short: "Valuable and actionable",
    content: `I had a really good <a href='https://www.datastrategypros.com/products/career-coaching' target='_blank' rel='noreferrer'>Career Coaching</a> session with Saif. He was friendly, knowledgeable, and professional. The insight I gained from the session was valuable and actionable. Would highly recommend.`,
    plainText: `I had a really good Career Coaching session with Saif. He was friendly, knowledgeable, and professional. The insight I gained from the session was valuable and actionable. Would highly recommend.`,
  },
  {
    id: "career-coaching-001",
    topic: "Career Services",
    name: "Matthew Carbone",
    img: Carbone,
    title: "Student",
    short: "Would highly recommend",
    content: `I just graduated college and have been looking for a job in data for a while. I was then introduced to Nicole through a mutual. We talked over the phone and she was really nice and understanding about my whole situation and provided me with advice that I could use to secure a job.
    She also runs a really informative <a href='https://www.datastrategypros.com/newsletter/archive' target='_blank' rel='noreferrer'>data newsletter</a> with a lot of interesting information and insights. Would highly recommend if you need a data <a href='https://www.datastrategypros.com/products/career-coaching' target='_blank' rel='noreferrer'>Career Coach</a>.`,
    plainText: `I just graduated college and have been looking for a job in data for a while. I was then introduced to Nicole through a mutual. We talked over the phone and she was really nice and understanding about my whole situation and provided me with advice that I could use to secure a job. She also runs a really informative data newsletter with a lot of interesting information and insights. Would highly recommend if you need a data Career Coach.`,
  },
  {
    id: "career-coaching-002",
    topic: "Career Services",
    name: "Shae Ochoa",
    img: Ochoa,
    title: "Data Analyst",
    short: "Highly recommend",
    content: `I met with Nicole and was able to get connected to some great resources and received advice on next steps for my education and career. Highly recommend speaking with her [for <a href='https://www.datastrategypros.com/products/career-coaching' target='_blank' rel='noreferrer'>Career Coaching</a>] if you're looking for guidance!`,
    plainText: `I met with Nicole and was able to get connected to some great resources and received advice on next steps for my education and career. Highly recommend speaking with her if you're looking for guidance!`,
  },
  {
    id: "career-coaching-003",
    topic: "Career Services",
    name: "Christopher Nellas",
    img: Nellas,
    title: "Regional Cloud Solutions Lead, APACRegional Cloud Solutions Lead",
    short: "Paved my path for my career",
    content: `As a data professional, I am very thankful to have met Nicole and Marc.
    Their guidance has paved my path for my career and projects within my organization.
    In addition to the clear CDMP coaching guidelines, it provided me with a clear direction
    for how to deal with actual data management projects. Kudos to the team, and continue to enable data education.`,
    plainText: `As a data professional, I am very thankful to have met Nicole and Marc.
    Their guidance has paved my path for my career and projects within my organization.
    In addition to the clear CDMP coaching guidelines, it provided me with a clear direction
    for how to deal with actual data management projects. Kudos to the team, and continue to enable data education.`,
  },
  {
    id: "data-strategy-bootcamp-000",
    topic: "Data Strategy",
    name: "Gayle Rice",
    img: Rice,
    title: "Senior Data Analyst",
    short: "I knew that I would have a great foundation",
    content: `The <a href='https://www.datastrategypros.com/landing/data-strategy-bootcamp' target='_blank' rel='noreferrer'>Bootcamp</a> was so helpful. I knew that I would have a great foundation...  I'm so glad I found your <a href="https://www.datastrategypros.com/resources/cdmp" target="_blank">articles</a> and <a href='https://www.facebook.com/groups/346145433213551' target='_blank' rel='noreferrer'>Facebook group</a>!  Community is so important!`,
    plainText: `The Bootcamp was so helpful — I knew that I would have a great foundation...  I'm so glad I found your <a href="https://www.datastrategypros.com/resources/cdmp" target="_blank">articles</a> and <a href='https://www.facebook.com/groups/346145433213551' target='_blank' rel='noreferrer'>Facebook group</a>!  Community is so important!`,
  },
  {
    id: "cdmp-small-group-000",
    topic: "Data Strategy",
    name: "Gayle Rice",
    img: Rice,
    title: "Senior Data Analyst",
    short: "I knew that I would have a great foundation",
    content: `I knew that I would have a great foundation...  I'm so glad I found your <a href="https://www.datastrategypros.com/resources/cdmp" target="_blank">articles</a> and <a href='https://www.facebook.com/groups/346145433213551' target='_blank' rel='noreferrer'>Facebook group</a>!  Community is so important!`,
    plainText: `I knew that I would have a great foundation...  I'm so glad I found your <a href="https://www.datastrategypros.com/resources/cdmp" target="_blank">articles</a> and <a href='https://www.facebook.com/groups/346145433213551' target='_blank' rel='noreferrer'>Facebook group</a>!  Community is so important!`,
  },
  {
    id: "data-strategy-bootcamp-001",
    topic: "Data Strategy",
    name: "Bearden Barnes",
    img: Barnes,
    title: "Data Governance Consultant",
    short: "Great guidance on breaking down the material",
    content: `I found the <a href='https://www.datastrategypros.com/landing/data-strategy-bootcamp' target='_blank' rel='noreferrer'>CDMP Bootcamp</a> very helpful last year. It really helped me focus in on preparing for the exam. Having a cohort of similar people working towards the same goal, same schedule was invaluable.<br/><br/>
      "Yes, you do have to put in the time & effort to actually read, understand, and annotate the <a href='https://amzn.to/32oK8hH' target='_blank' rel='noreferrer'><em>DMBOK2</em></a>. Data Strategy Professionals provided great guidance on breaking down the material into manageable chunks and offered pointers to prepare for the exam.<br/><br/>
      "If you've thought about going for the <a href='https://www.datastrategypros.com/resources/cdmp-fundamentals-exam' target='_blank' rel='noreferrer'>CDMP certification</a>, I can recommend this program to help you get there!`,
    plainText: `I found this program very helpful last year. It really helped me focus in on preparing for the exam. Having a cohort of similar people working towards the same goal, same schedule was invaluable.<br/><br/>
      "Yes, you do have to put in the time & effort to actually read, understand, and annotate the <a href='https://amzn.to/32oK8hH' target='_blank' rel='noreferrer'><em>DMBOK2</em></a>. Data Strategy Professionals provided great guidance on breaking down the material into manageable chunks and offered pointers to prepare for the exam.<br/><br/>
      "If you've thought about going for the <a href='https://www.datastrategypros.com/resources/cdmp-fundamentals-exam' target='_blank' rel='noreferrer'>CDMP certification</a>, I can recommend this program to help you get there!`,
  },
  {
    id: "cdmp-small-group-001",
    topic: "Data Strategy",
    name: "Bearden Barnes",
    img: Barnes,
    title: "Data Governance Consultant",
    short: "Great guidance on breaking down the material",
    content: `Having a cohort of similar people working towards the same goal, same schedule was invaluable... Data Strategy Professionals provided great guidance on breaking down the material into manageable chunks and offered pointers to prepare for the exam.`,
    plainText: `Having a cohort of similar people working towards the same goal, same schedule was invaluable... Data Strategy Professionals provided great guidance on breaking down the material into manageable chunks and offered pointers to prepare for the exam.`,
  },
  {
    id: "data-strategy-bootcamp-002",
    topic: "Data Strategy",
    name: "Jacinda Kidd",
    img: Kidd,
    title: "Associate",
    short: "This is how I obtained my CDMP!",
    content: `This is how I obtained my <a href='https://www.datastrategypros.com/resources/cdmp-fundamentals-exam' target='_blank' rel='noreferrer'>CDMP</a>! Thanks a million, Nicole!`,
    plainText: `This is how I obtained my CDMP! Thanks a million, Nicole!`,
  },
  {
    id: "data-strategy-bootcamp-003",
    topic: "Data Strategy",
    name: "Jacinda Kidd",
    img: Kidd,
    title: "Associate",
    short: "This is how I obtained my CDMP!",
    content: `[The <a href='https://www.datastrategypros.com/landing/data-strategy-bootcamp' target='_blank' rel='noreferrer'>CDMP Bootcamp</a>] is how I obtained my <a href='https://www.datastrategypros.com/resources/cdmp-fundamentals-exam' target='_blank' rel='noreferrer'>CDMP</a>! Thanks a million, Nicole!`,
    plainText: `[The CDMP Bootcamp] is how I obtained my CDMP! Thanks a million, Nicole!`,
  },
  {
    id: "data-strategy-bootcamp-004",
    topic: "Data Strategy",
    name: "Bearden Barnes",
    img: Barnes,
    title: "Data Governance Consultant",
    short: "Great guidance on breaking down the material",
    content: `I found the <a href='https://www.datastrategypros.com/landing/data-strategy-bootcamp' target='_blank' rel='noreferrer'>CDMP Bootcamp</a> very helpful last year. It really helped me focus in on preparing for the exam. Having a cohort of similar people working towards the same goal, same schedule was invaluable. If you've thought about going for the <a href='https://www.datastrategypros.com/resources/cdmp-fundamentals-exam' target='_blank' rel='noreferrer'>CDMP certification</a>, I can recommend this program to help you get there!`,
    plainText: `I found this program very helpful last year. It really helped me focus in on preparing for the exam. Having a cohort of similar people working towards the same goal, same schedule was invaluable. If you've thought about going for the <a href='https://www.datastrategypros.com/resources/cdmp-fundamentals-exam' target='_blank' rel='noreferrer'>CDMP certification</a>, I can recommend this program to help you get there!`,
  },
  {
    id: "data-strategy-bootcamp-005",
    topic: "Data Strategy",
    name: "Andrea Cavalluzzo",
    img: Cavalluzo,
    title: "Senior Technical Consultant",
    short: "Many salient points",
    content: "The practice questions are sound, and the training slides are hitting on many salient points.",
    plainText: "The practice questions are sound, and the training slides are hitting on many salient points.",
  },
  {
    id: "data-strategy-bootcamp-006",
    topic: "Data Strategy",
    name: "Steve Peart",
    img: Peart,
    title: "IT Risk Manager",
    short: "VERY helpful",
    content: `I found the <a href='https://www.datastrategypros.com/landing/data-strategy-bootcamp' target='_blank' rel='noreferrer'>Bootcamp</a>
    to be essential to my passing the exam. The practice tests were VERY helpful. The fast-paced, focused review was also helpful.`,
    plainText:
      "I found the Bootcamp to be essential to my passing the exam. The practice tests were VERY helpful. The fast-paced, focused review was also helpful.",
  },
  {
    id: "data-strategy-bootcamp-007",
    topic: "Data Strategy",
    name: "John O'Donovan",
    img: ODonovan,
    title: "Data Strategy Consultant",
    short: "I felt better prepared",
    content:
      "Very interactive and just the right amount of focus for lesser weighted chapters vs heavier weighted chapters...  Overall, I felt better prepared for the test when test time arrived!",
    plainText:
      "Very interactive and just the right amount of focus for lesser weighted chapters vs heavier weighted chapters...  Overall, I felt better prepared for the test when test time arrived!",
  },
  {
    id: "data-strategy-bootcamp-008",
    topic: "Data Strategy",
    name: "John O'Donovan",
    img: ODonovan,
    title: "Data Strategy Consultant",
    short: "I felt better prepared",
    content:
      "[The <a href='https://www.datastrategypros.com/landing/data-strategy-bootcamp' target='_blank' rel='noreferrer'>CDMP Bootcamp</a> was] very interactive and just the right amount of focus for lesser weighted chapters vs heavier weighted chapters...  Overall, I felt better prepared for the test when test time arrived!",
    plainText:
      "[The CDMP Bootcamp was] very interactive and just the right amount of focus for lesser weighted chapters vs heavier weighted chapters...  Overall, I felt better prepared for the test when test time arrived!",
  },
  {
    id: "data-strategy-bootcamp-009",
    topic: "Data Strategy",
    name: "Courtney Bush",
    img: Bush,
    title: "Vice President, Data and Application Integration",
    short: "Fit my needs perfectly",
    content: `At the end, I was bummed the <a href='https://www.datastrategypros.com/landing/data-strategy-bootcamp' target='_blank' rel='noreferrer'>CDMP Bootcamp</a> was over.
    Thank you for keeping the discussions and comments focused on the <a href='https://www.datastrategypros.com/resources/cdmp-fundamentals-exam' target='_blank' rel='noreferrer'>exam</a>.
    Enjoyed reviewing both practice exams (of day 1 and 2) at the end of day 2.  Really helped bring it all together. So much so that I <em>may</em> have had the confidence to do the exam and <em>may</em> have passed.
    I felt I was ready at the end of Bootcamp. The style fit my needs perfectly, thank you.  I look forward to continuing to learn within the community, even though the exam is behind me.`,
    plainText: `At the end, I was bummed it was over.  Thank you for keeping the discussions and comments focused on the exam.  Enjoyed reviewing both practice exams (of day 1 and 2) at the end of day 2.  Really helped bring it all together. So much so that I <em>may</em> have had the confidence to do the exam and <em>may</em> have passed.  I felt I was ready at the end of Bootcamp.  The style fit my needs perfectly, thank you.  I look forward to continuing to learn within the community, even though the exam is behind me.`,
  },
  {
    id: "data-strategy-bootcamp-010",
    topic: "Data Strategy",
    name: "Madison Turner",
    img: Turner,
    title: "Systems Metadata Specialist",
    short: "Enjoyed this — thank you",
    content: `I have taken the DATAVERSITY CDMP Bootcamp and can say I personally enjoyed [Data Strategy Professionals' <a href='https://www.datastrategypros.com/landing/data-strategy-bootcamp' target='_blank' rel='noreferrer'>CDMP Bootcamp</a>] more regardless of any hiccups. Thank you so much for all you do!`,
    plainText: `I have taken the DATAVERSITY CDMP Bootcamp and can say I personally enjoyed this more regardless of any hiccups. Thank you so much for all you do!`,
  },
  {
    id: "data-strategy-bootcamp-011",
    topic: "Data Strategy",
    name: "Kevin J. Kozinski",
    title: "Sr. Data and Analytics Engineer",
    short: "Thank you",
    content: `Thank you for this week's <a href='https://www.datastrategypros.com/landing/data-strategy-bootcamp' target='_blank' rel='noreferrer'>CDMP Bootcamp</a>. I appreciated your team's patient behind-the-scenes help and calm, professional demeanor... Thanks for sharing your knowledge and expertise with us!`,
    plainText: `Thank you for this week's CDMP Bootcamp. I appreciated your team's patient behind-the-scenes help and calm, professional demeanor... Thanks for sharing your knowledge and expertise with us!`,
  },
  {
    id: "enterprise-custom-training-000",
    topic: "Data Strategy",
    name: "Emmett Reilly",
    img: Reilly,
    title: "Engineering and Analytics Manager",
    short: "Really helped get a great test result",
    content: `Really appreciate the opportunity to do [<a href='https://www.datastrategypros.com/landing/enterprise-custom-training'>Enterprise Custom Training</a>] and the quality of the presentations and materials — I definitely think I can use this in my day to day role, and it really helped get a great test result.`,
    plainText: `Really appreciate the opportunity to do [training with Data Strategy Professionals] and the quality of the presentations and materials — I definitely think I can use this in my day to day role, and it really helped get a great test result.`,
  },
  {
    id: "data-strategy-workbook-000",
    topic: "Data Strategy",
    name: "Amy Bamburg",
    img: Bamburg,
    title: "Data Enablement and Administration Leader",
    short: "VERY good!",
    content: `The <strong>Data Strategy documents</strong> that this group publishes are VERY good!  I have used some of the <a href='https://www.datastrategypros.com/products/data-strategy-workbook' target='_blank' rel='noreferrer'>strategy approach items</a> and <a href='https://www.datastrategypros.com/products/document-checklist' target='_blank' rel='noreferrer'>checklists</a> in my role at the new company.`,
    plainText: `The Data Strategy documents that this group publishes are VERY good!  I have used some of the strategy approach items and checklist in my role at the new company.`,
  },
  {
    id: "data-management-master-class-000",
    topic: "Data Strategy",
    name: "Ali Fiaz",
    img: Fiaz,
    title: "Senior Data Scientist",
    short: "Recommended resource to bridge the knowledge gap",
    content: `Given the scenario-based questions on the CDMP exam that extend beyond the  <a href='https://amzn.to/32oK8hH' target='_blank' rel='noreferrer'><em>DMBOK2</em></a> book, it's crucial to broaden your understanding. <a href='https://www.datastrategypros.com/products/data-management-master-class' target='_blank' rel='noreferrer'>Data Management Master Class</a> by Data Strategy Professionals is a recommended resource to bridge this knowledge gap.`,
    plainText: `Given the scenario-based questions on the CDMP exam that extend beyond the <em>DMBOK2</em> book, it's crucial to broaden your understanding. Data Management Master Class by Data Strategy Professionals is a recommended resource to bridge this knowledge gap.`,
  },
  {
    id: "data-management-master-class-001",
    topic: "Data Strategy",
    name: "Kevin McBeth",
    img: McBeth,
    title: "MLOps Engineer",
    short: "Video production is at corporate training quality",
    content: `The <a href='https://www.datastrategypros.com/products/data-management-master-class' target='_blank' rel='noreferrer'>Data Management Master Class</a> is good.  Video production is at corporate training quality.`,
    plainText: `It's good.  Video production is at corporate training quality.`,
  },

  // {
  //   id: "",
  //   topic: "",
  //   name: "",
  //   img: "",
  //   title: "",
  //   short: "",
  //   content: ``,
  //   plainText: ``,
  // },
];

export default testimonials;
