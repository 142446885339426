import Bills from "../img/people/nicole_bills.webp";
import Saif from "../img/people/saif_sheikh.webp";
import Katrina from "../img/people/katrina_garcia.webp";
import Carlos from "../img/people/carlos_navarro.webp";
import Mac from "../img/people/mac_jordan.webp";
import John from "../img/people/john_odonovan.webp";
import Lesh from "../img/people/kathryn_lesh.webp";
import Becky from "../img/people/becky_maudlin.webp";
import Connor from "../img/people/connor_mclaren_kennedy.webp";
import Alyana from "../img/people/alyana_dela_cruz.webp";

const team: TeamMember[] = [
  {
    id: "Nicole",
    img: Bills,
    name: "Nicole Janeway Bills",
    linkedin: "https://www.linkedin.com/in/nicole-janeway-bills/",
    title: "Founder & CEO",
    content: `Nicole has four years of experience providing training for data-related exams.
      She offers a proven track record of applying Data Strategy and related disciplines to solve clients' most pressing challenges.
      She has worked as a Data Scientist and Project Manager for federal and commercial consulting teams, writing 35+ <a href="https://medium.com/@nicolejaneway" target="_blank" rel="noreferrer">Medium articles</a> along the way.
      Her business experience includes natural language processing, cloud computing, statistical testing, pricing analysis, ETL processes, and web and application development.
      She attained recognition from DAMA for a Master-level pass of the 
      <a href='https://www.datastrategypros.com/resources/cdmp-fundamentals-exam' target='_blank' rel='noreferrer'>CDMP Fundamentals Exam</a> and the 
      <a href='https://www.datastrategypros.com/resources/cdmp-specialist-exam#dq' target='_blank' rel='noreferrer'>Data Quality Specialist Exam</a>.`,
  },
  {
    id: "John",
    img: John,
    name: "John O'Donovan",
    linkedin: "https://www.linkedin.com/in/johnpodonovan/",
    title: "CDMP Training Lead",
    content: `John is a Data Management professional with global experience leading end-to-end solution deployments, 
    data governance, data strategy, and technology integration initiatives across various industries.
    He excels in working with business leaders to map complex concepts into executable strategies,
    building high-performance global teams, leading multi-million dollar cross-functional projects,
    and implementing scalable architectures leveraging various technology platforms.
    John's unique blend of business knowledge and technical expertise enables him to engage teams at all organizational levels.
    He has led data-driven decision implementations with companies such as The Home Depot, Macy's, Procter & Gamble, Teradata, and GE-Plastics.
    He is recognized by DAMA for a Master-level passing score of the <a href='https://www.datastrategypros.com/resources/cdmp-fundamentals-exam' target='_blank' rel='noreferrer'>CDMP Fundamentals Exam</a>.`,
  },
  {
    id: "Kathryn",
    img: Lesh,
    name: "Kathryn Lesh, PhD, CAP, CDMP",
    linkedin: "https://www.linkedin.com/in/kathryn-lesh-20047328b/",
    title: "Analytics & Education Consultant",
    content: `Kathryn is a mathematician and analytics expert with decades of experience in data analysis, mathematical research, and teaching.
    With many research publications and presentations to her credit, Kathryn demonstrates the joy of lifelong learning and rigorous thought.
    She is fascinated by the stories found in data, and she enjoys helping others to develop the skills to uncover insights through the responsible and thoughtful use of data analytics.
    Kathryn is recognized by INFORMS as a Certified Analytics Professional
    (<a href='https://www.datastrategypros.com/resources/certified-analytics-professional' target='_blank' rel='noreferrer'>CAP</a>).
    She is recognized by DAMA as a CDMP Practitioner, with Master-level passes of the
    <a href='https://www.datastrategypros.com/resources/cdmp-fundamentals-exam' target='_blank' rel='noreferrer'>CDMP Fundamentals</a>,
    <a href='https://www.datastrategypros.com/resources/cdmp-specialist-exam' target='_blank' rel='noreferrer'>Data Quality</a>,
    and  <a href='https://www.datastrategypros.com/resources/cdmp-specialist-exam' target='_blank' rel='noreferrer'>Data Governance</a> exams. She has a Ph.D. in mathematics from M.I.T. `,
  },
  {
    id: "Carlos",
    img: Carlos,
    name: "Carlos Navarro",
    linkedin: "https://www.linkedin.com/in/carlosnavarrocrossman/",
    title: "Discussion Group Lead & Career Coach",
    content: `With over 20 years of extensive experience, Carlos is recognized for his proficiency in designing, constructing,
    and enhancing data practices within diverse realms such as data strategy, management, and visualization, with a specialized
    focus on leading cloud data platforms. He is recognized by DAMA as a
    <a href='https://www.datastrategypros.com/resources/cdmp-fundamentals-exam' target='_blank' rel='noreferrer'>Certified Data Management Professional (CDMP)</a>.`,
  },
  {
    id: "Saif",
    img: Saif,
    name: "Saif Sheikh",
    linkedin: "https://www.linkedin.com/in/sef-sheikh/",
    title: "Discussion Group Lead & Career Coach",
    content: `Saif brings an informed perspective to
      <a href='https://www.datastrategypros.com/products/career-coaching' target='_blank' rel='noreferrer'>Career Coaching</a>
      with advice backed by a wealth of experience working in Data Governance and a master's degree from Georgetown University
      in Technology Management, Information Systems, and Business.
      He has extensive involvement in crafting, executing, and fortifying Data Governance initiatives across industries such as finance, staffing, and healthcare.
      Saif enjoys helping others figure out the next step in their career. He is recognized as a
      <a href='https://www.datastrategypros.com/resources/cdmp-fundamentals-exam' target='_blank' rel='noreferrer'>CDMP</a> and has taught two
      <a href='https://www.datastrategypros.com/landing/data-strategy-bootcamp' target='_blank' rel='noreferrer'>CDMP Bootcamps</a> through Data Strategy Professionals.`,
  },
  {
    id: "Becky",
    img: Becky,
    name: "Becky Maudlin",
    title: "Instructional Designer",
    content: `Becky is a seasoned educator and technical writer with over 25 years of diverse professional experience.
    She began her career as a high school math teacher, spending over a decade making math easier and more interesting for her students.
    This dedication to clear communication naturally led her to a career in technical writing –
    creating, proofing, and editing content that simplifies complex ideas.
    With an additional 6 years of experience as a programmer, Becky brings a solid technical background to her work for Data Strategy Professionals.
    She is driven by a commitment to creating useful, understandable instructional content.`,
  },
  {
    id: "Mac",
    img: Mac,
    name: "Mac Jordan",
    title: "Research Specialist & Content Writer",
    content: `Mac supports Data Strategy Professionals with research into Data Management trends.
    He is a frequent contributor to the
    <a href='https://www.datastrategypros.com/newsletter' target='_blank' rel='noreferrer'>newsletter</a>
    and has written articles on topics such as 
    <a href='https://www.datastrategypros.com/resources/data-management-maturity-assessment-dmma' target='_blank' rel='noreferrer'>Data Management Maturity Assessments</a>,
    <a href='https://www.datastrategypros.com/resources/data-driven-decision-making' target='_blank' rel='noreferrer'>developing a data-driven culture</a>,
    and 
    <a href='https://www.datastrategypros.com/resources/data-management-job-projections' target='_blank' rel='noreferrer'>job market projections for data-related roles</a>.`,
  },
  {
    id: "Connor",
    img: Connor,
    name: "Connor McLaren-Kennedy",
    title: "Content Writer",
    content: `Since graduating from the University of Queensland with a degree in Writing, Journalism, and Communications,
    Connor has spent his entire professional life as a writer. At Data Strategy Professionals, he supports with content creation, 
    helping out with <a href='https://www.datastrategypros.com/resources' target='_blank' rel='noreferrer'>blog posts</a> and
    <a href='https://www.datastrategypros.com/newsletter' target='_blank' rel='noreferrer'>newsletter</a> content.`,
  },
  {
    id: "Katrina",
    img: Katrina,
    name: "Katrina Garcia",
    title: "Operations Specialist",
    content: `Katrina manages customers' concerns to ensure data practitioners have a seamless experience with support and training.
    Off the job, she enjoys traveling and listening to metal music.`,
  },
  {
    id: "Alyana",
    img: Alyana,
    name: "Alyana Dela Cruz",
    title: "Operations Specialist",
    content: `Alyana is responsible for providing administrative support to the team,
    as well as assisting with special projects and initiatives.
    Off-duty, she's on a self-learning journey to master coding, and she also has a passion for gaming.`,
  },
];

export default team;
