import { useState } from "react";

import PartnerProductPage from "../../../components/product-pages/PartnerProductPage";
import CDMPLevelsModal from "../../../components/CDMPLevelsModal";
import CustomModal from "../../../components/CustomModal";
import { Instructors } from "../../../components/product-pages/Coaches";
import { CDMPBootcampTealBox, CDMPBootcampTestimonials, CDMPBootcampFeatures } from "./CDMPBootcampComponents";
import ShaulisTealBox from "../../../components/product-pages/ShaulisTealBox";

const PartnerCDMPBootcamp: React.FC<{ product: productName; link: string; mockExamCount: number }> = ({
  product,
  link,
  mockExamCount,
}) => {
  const [showCDMPLevelsModal, setShowCDMPLevelsModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState("");
  const [alt, setAlt] = useState("");

  const handleShowClick = (event) => {
    setShowModal(!showModal);
    setImage(event.currentTarget.src);
    setAlt(event.currentTarget.alt);
  };

  return (
    <>
      {showCDMPLevelsModal && <CDMPLevelsModal setShowModal={setShowCDMPLevelsModal} />}
      {showModal && <CustomModal image={image} alt={alt} handleShowClick={handleShowClick} />}
      <PartnerProductPage
        PRODUCT={product}
        tealBox={<CDMPBootcampTealBox />}
        rest={<Rest mockExamCount={mockExamCount} />}
        purchaseLink={link}
      />
    </>
  );
};

const Rest: React.FC<{ mockExamCount: number }> = ({ mockExamCount }) => {
  return (
    <>
      <Instructors instructors={["John"]} />
      <CDMPBootcampTestimonials />
      <CDMPBootcampFeatures mockExams={mockExamCount} />
      <ShaulisTealBox />
    </>
  );
};

export default PartnerCDMPBootcamp;
