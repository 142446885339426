import bids from "../img/logos/logo_bids.webp";
import bidsPreview from "../img/helmet/products/logo_bids.jpeg";
import boardroom from "../img/products/data-strategy-workbook/boardroom.webp";
import boardroomPreview from "../img/helmet/products/boardroom.jpeg";
import bootcamp from "../img/products/enterprise/cdmp7.webp";
import bootcampPreview from "../img/helmet/products/cdmp7.jpg";
// import calendar from "../img/products/courses/calendar.webp";
// import sep from "../img/products/bootcamps/sep.webp";
// import sepPreview from "../img/helmet/products/sep.jpg";
import oct from "../img/products/bootcamps/oct.webp";
import octPreview from "../img/helmet/products/oct.jpg";
import jan from "../img/products/bootcamps/jan.webp";
import janPreview from "../img/helmet/products/jan.jpeg";
import cdmpSmallGroupOld from "../img/products/bootcamps/cdmp-small-group.webp";
import cdmpSmallGroupPreviewOld from "../img/helmet/products/cdmp-small-group.jpeg";
import cdmpSmallGroup from "../img/products/bootcamps/q1.webp";
import cdmpSmallGroupPreview from "../img/helmet/products/q1.jpeg";
import capBundle from "../img/products/bundles/glasses.webp";
import capBundlePreview from "../img/helmet/products/glasses.jpeg";
import capQs from "../img/products/cap-exam-questions/cap-exam-questions.webp";
import capQsPreview from "../img/helmet/products/cap-exam-questions.jpeg";
import capStudent from "../img/products/cap-study-plan/cap-student.webp";
import capStudentPreview from "../img/helmet/products/cap-student.jpg";
import cdmp from "../img/products/cdmp-study-plan/linkedin.webp";
import cdmpPreview from "../img/helmet/products/linkedin.jpeg";
import cdmpBundle from "../img/products/bundles/woman-studying.webp";
import cdmpBundlePreview from "../img/helmet/products/woman-studying.jpeg";
import cdsQs from "../img/products/cds-exam-questions/studying.webp";
import cdsQsPreview from "../img/helmet/products/studying.jpeg";
import checklist from "../img/products/data-strategy-workbook/checklist.webp";
import checklistPreview from "../img/helmet/products/checklist.png";
import cippeBundle from "../img/products/bundles/coffee.webp";
import cippeBundlePreview from "../img/helmet/products/coffee.jpeg";
import cippeQs from "../img/products/data-privacy-exam-questions/cippe-qs.webp";
import cippeQsPreview from "../img/helmet/products/cippe-qs.jpeg";
import computer from "../img/products/courses/computer.webp";
import computerPreview from "../img/helmet/products/computer.png";
import crashCourse from "../img/products/courses/crash-course.webp";
import crashCoursePreview from "../img/helmet/products/crash-course.png";
import documents from "../img/products/services/documents.webp";
import documentsPreview from "../img/helmet/products/documents.jpeg";
import dsBundle from "../img/products/bundles/headphones.webp";
import dsBundlePreview from "../img/helmet/products/headphones.jpeg";
import examReadiness from "../img/products/cdmp-exam-readiness-check/highfive_sm.webp";
import examReadinessPreview from "../img/helmet/products/highfive_sm.jpeg";
import flags from "../img/products/data-privacy-study-plan/eu-flags.webp";
import flagsPreview from "../img/helmet/products/eu-flags.jpeg";
import gdpr from "../img/products/data-privacy-mind-maps/gdpr_lrgest.webp";
import gdprPreview from "../img/helmet/products/gdpr_lrgest.png";
import gdprCourse from "../img/products/courses/gdpr_sq.webp";
import gdprCoursePreview from "../img/helmet/products/gdpr_sq.jpg";
import mug from "../img/products/mug/mug15_with_laptop_crop.webp";
import mugPreview from "../img/helmet/products/mug.jpeg";
import notes from "../img/products/notes/ch3_sq.webp";
import notesPreview from "../img/helmet/products/ch3_sq.png";
import poster from "../img/products/poster/poster-mock-teal.webp";
import posterPreview from "../img/helmet/products/poster.png";
import qs from "../img/products/exam-questions/exam-taker_sq.webp";
import qsPreview from "../img/helmet/products/exam-taker_sq.jpg";
import resume from "../img/products/services/resume.webp";
import resumePreview from "../img/helmet/products/resume.jpg";
import smiling from "../img/products/membership/smiling_sq.webp";
import smilingPreview from "../img/helmet/products/smiling_sq.jpeg";
import specialistBundle from "../img/products/bundles/man-studying.webp";
import specialistBundlePreview from "../img/helmet/products/man-studying.jpeg";
import spnotes from "../img/products/notes/specialist_sq.webp";
import spnotesPreview from "../img/helmet/products/specialist_sq.jpeg";
import spqs from "../img/products/exam-questions/specialist-questions.webp";
import spqsPreview from "../img/helmet/products/specialist-questions.jpeg";
import training from "../img/products/membership/training.webp";
import trainingPreview from "../img/helmet/products/training.png";
import tshirt from "../img/products/tshirt/tshirt_crop.webp";
import tshirtPreview from "../img/helmet/products/tshirt.jpeg";
import tutoring from "../img/products/services/tutoring.webp";
import tutoringPreview from "../img/helmet/products/tutoring.jpg";
import zoomWithSaif from "../img/products/services/zoom-with-saif.webp";
import zoomPreview from "../img/helmet/products/zoom.png";

const products: productType[] = [
  {
    name: "CDMP Small Group Study Materials",
    description: `Learn about Data Strategy for the CDMP Fundamentals Exam with our materials for study groups.`,
    payments: [],
    img: training,
    previewImg: trainingPreview,
    alt: "office workers",
    link: "/landing/small-group-study-materials",
    category: "enterprise",
    showOnHomepage: false,
    price: 28000,
    unit: "per cohort",
    bestValue: false,
    new: false,
    updated: false,
  },
  {
    name: "Two Day CDMP Bootcamp",
    description: `Intensive, in-person training that can serve as an engaging introduction to Data Strategy concepts
      or accelerate the learning of experienced practitioners.`,
    payments: [],
    img: bootcamp,
    previewImg: bootcampPreview,
    alt: "office workers",
    link: "/landing/data-strategy-bootcamp",
    category: "enterprise",
    showOnHomepage: false,
    price: 346500,
    unit: "per cohort",
    bestValue: false,
    new: false,
    updated: false,
  },
  {
    name: "CDMP Study Program",
    description: `Give your team access to our best selling study materials so they can ace
      the CDMP Fundamentals Exam and implement Data Strategy best practices in their work.`,
    payments: ["enterprise-study-program"],
    img: smiling,
    previewImg: smilingPreview,
    alt: "office workers making a cheer",
    link: "/landing/enterprise-membership",
    category: "enterprise",
    showOnHomepage: false,
    price: 39900,
    unit: "per participant",
    bestValue: false,
    new: false,
    updated: false,
  },
  {
    name: "Document Drafting & Review",
    description: `Our objective is to help you develop the best possible Data Strategy documents that unlock the value of data assets in support of your organization's mission.`,
    payments: [],
    img: documents,
    previewImg: documentsPreview,
    alt: "office workers",
    link: "/landing/document-drafting-and-review",
    category: "enterprise",
    showOnHomepage: false,
    bestValue: false,
    new: false,
    updated: false,
  },
  {
    name: "CDMP Small Group Sessions (Q4)",
    description: `Virtual study sessions for <strong>one hour</strong> each week taking place over <strong>13 weeks</strong>,
      starting Friday, Sep 20 at 12pm ET. Includes access to <strong>session recordings</strong>,
      <strong>CDMP Study Plan</strong>, and <strong>140 practice questions</strong>.`,
    payments: ["cdmp-small-group"],
    img: cdmpSmallGroupOld,
    previewImg: cdmpSmallGroupPreviewOld,
    alt: "calendar",
    link: "/products/cdmp-small-group-sessions-q4",
    category: "cdmp-fundamentals",
    showOnHomepage: false,
    price: 35000,
    bestValue: false,
    new: true,
    updated: false,
  },
  // {
  //   name: "DAMA WI CDMP Bootcamp (Sep 24 and 25)",
  //   description: `Join us in Madison, WI for a CDMP Bootcamp on Sep 24 and 25. Advance your skills and master the 14 chapters of the <em>DMBOK</em> tested on the exam.`,
  //   payments: [],
  //   img: sep,
  //   previewImg: sepPreview,
  //   alt: "calendar",
  //   link: "/products/dama-wi-cdmp-bootcamp",
  //   category: "cdmp-fundamentals",
  //   showOnHomepage: true,
  //   price: 15000,
  //   bestValue: false,
  //   new: false,
  //   updated: false,
  // },
  {
    name: "DAMA GA CDMP Bootcamp (Oct 4 and 5)",
    description: `Join us in Atlanta, GA for a CDMP Bootcamp on Oct 4 and 5. Advance your skills and master the 14 chapters of the <em>DMBOK</em> tested on the exam.`,
    payments: [],
    img: oct,
    previewImg: octPreview,
    alt: "calendar",
    link: "/products/dama-ga-cdmp-bootcamp",
    category: "cdmp-fundamentals",
    showOnHomepage: false,
    price: 37500,
    bestValue: false,
    new: false,
    updated: false,
  },
  {
    name: "Virtual CDMP Bootcamp (Jan 10 and 11)",
    description: `Join us for a two-day CDMP Bootcamp over Zoom. Advance your skills and master the 14 chapters of the <em>DMBOK</em> tested on the exam.`,
    payments: ["cdmp-bootcamp"],
    img: jan,
    previewImg: janPreview,
    alt: "calendar",
    link: "/products/virtual-cdmp-bootcamp",
    category: "cdmp-fundamentals",
    showOnHomepage: true,
    price: 32000,
    bestValue: false,
    new: true,
    updated: false,
  },
  {
    name: "CDMP Small Group Sessions (Q1)",
    description: `Virtual study sessions for <strong>one hour</strong> each week taking place over <strong>13 weeks</strong>,
      starting Friday, Jan 24 at 12pm ET. Includes access to <strong>session recordings</strong>,
      <strong>CDMP Study Plan</strong>, and <strong>140 practice questions</strong>.`,
    payments: ["cdmp-small-group"],
    img: cdmpSmallGroup,
    previewImg: cdmpSmallGroupPreview,
    alt: "calendar",
    link: "/products/cdmp-small-group-sessions",
    category: "cdmp-fundamentals",
    showOnHomepage: true,
    price: 35000,
    bestValue: false,
    new: true,
    updated: false,
  },
  {
    name: "CDMP Crash Course",
    description: `Prepare for the CDMP Fundamentals Exam with this helpful nine chapter video course.
    The course provides 83 lessons on the most important topics for the exam, 8 lessons on study tips, and 6 lessons on CDMP exam logistics.`,
    payments: ["cdmp-crash-course"],
    img: crashCourse,
    previewImg: crashCoursePreview,
    alt: "video course",
    link: "/products/cdmp-crash-course",
    category: "cdmp-fundamentals",
    showOnHomepage: true,
    price: 18500,
    bestValue: false,
    new: false,
    updated: false,
  },
  {
    name: "CDMP Tutoring",
    description: `Advance your readiness for the CDMP Fundamentals and/or Specialist Exams with the help of one of our expert CDMP Tutors.`,
    payments: ["career-coaching"],
    img: tutoring,
    previewImg: tutoringPreview,
    alt: "man on a video call with a laptop",
    link: "/products/cdmp-tutoring",
    category: "cdmp-fundamentals",
    showOnHomepage: true,
    price: 8500,
    bestValue: false,
    new: false,
    updated: false,
  },
  {
    name: "CDMP Fundamentals Bundle",
    description: `Fully prepare for the CDMP Fundamentals Exam with all our best selling study materials:
      the <strong>CDMP Study Plan</strong>, <strong>Fundamentals Notes</strong>, <strong>Question Sets</strong>,
      and <strong>Exam Readiness Check</strong>.`,
    payments: ["cdmp-fundamentals-bundle"],
    img: cdmpBundle,
    previewImg: cdmpBundlePreview,
    alt: "product bundle",
    link: "/products/cdmp-fundamentals-bundle",
    category: "cdmp-fundamentals",
    showOnHomepage: true,
    price: 23000,
    bestValue: true,
    new: false,
    updated: false,
  },
  {
    name: "CDMP Study Plan",
    description: `Maximize your time, energy, and motivation to become a Data Strategist.
      Each week, you can expect 5-6 emails covering each of the 14 chapters tested on the exam.`,
    payments: ["cdmp-study-plan", "cdmp-study-plan-continuation", "cdmp-study-plan-free-trial"],
    img: cdmp,
    previewImg: cdmpPreview,
    alt: "professional woman with laptop",
    link: "/products/cdmp-study-plan",
    category: "cdmp-fundamentals",
    showOnHomepage: true,
    price: 11500,
    bestValue: false,
    new: false,
    updated: false,
  },
  {
    name: "CDMP Fundamentals Notes",
    description: "Fast-track your preparation for CDMP exams with this 30+ page notes document.",
    payments: ["cdmp-fundamentals-notes"],
    img: notes,
    previewImg: notesPreview,
    alt: "fundamentals notes",
    link: "/products/cdmp-fundamentals-notes",
    category: "cdmp-fundamentals",
    showOnHomepage: true,
    price: 4500,
    bestValue: false,
    new: false,
    updated: false,
  },
  {
    name: "CDMP Question Sets",
    description: "Advance your knowledge with practice questions for the Certified Data Management Professional Exams.",
    payments: ["cdmp-question-set"],
    img: qs,
    previewImg: qsPreview,
    alt: "exam questions",
    link: "/products/cdmp-question-sets",
    category: "cdmp-fundamentals",
    showOnHomepage: true,
    price: 2500,
    bestValue: false,
    new: false,
    updated: false,
  },
  {
    name: "CDMP Exam Readiness Check",
    description: "Ensure you're ready to ace the CDMP Fundamentals Exam with this two part assessment.",
    payments: ["cdmp-exam-readiness-check"],
    img: examReadiness,
    previewImg: examReadinessPreview,
    alt: "readiness check",
    link: "/products/cdmp-exam-readiness-check",
    category: "cdmp-fundamentals",
    showOnHomepage: true,
    price: 1500,
    bestValue: false,
    new: false,
    updated: false,
  },
  {
    name: "Data Management Master Class",
    description: "This comprehensive video course covers the fundamentals of Data Management.",
    payments: ["data-management-master-class"],
    img: computer,
    previewImg: computerPreview,
    alt: "laptop sitting on a couch showing an aiken framework",
    link: "/products/data-management-master-class",
    category: "data-strategy",
    showOnHomepage: true,
    price: 44900,
    bestValue: false,
    new: false,
    updated: false,
  },
  {
    name: "Data Management Master Class Free Trial",
    description: "This comprehensive video course covers the fundamentals of Data Management.",
    payments: ["data-management-master-class-free-trial"],
    img: computer,
    previewImg: computerPreview,
    alt: "laptop sitting on a couch showing an aiken framework",
    link: "/products/data-management-master-class",
    category: "data-strategy",
    showOnHomepage: false,
    price: 0,
    bestValue: false,
    new: false,
    updated: false,
  },
  {
    name: "Data Strategy Workbook",
    description: "Improve the Data Strategy capabilities at your organization with this set of 20 exercises.",
    payments: ["data-strategy-workbook"],
    img: boardroom,
    previewImg: boardroomPreview,
    alt: "group sitting at table",
    link: "/products/data-strategy-workbook",
    category: "data-strategy",
    showOnHomepage: true,
    price: 4900,
    bestValue: false,
    new: false,
    updated: false,
  },
  {
    name: "Data Strategy Document Checklist",
    description: "A comprehensive list of Data Strategy documents, inputs, and deliverables.",
    payments: ["document-checklist"],
    img: checklist,
    previewImg: checklistPreview,
    alt: "group sitting at table",
    link: "/products/document-checklist",
    category: "data-strategy",
    showOnHomepage: true,
    price: 1800,
    bestValue: false,
    new: false,
    updated: false,
  },
  {
    name: "Specialist Bundle",
    description:
      "Fully prepare for the CDMP Specialist Exams with your choice of <strong>two Specialist Guides</strong> and <strong>two Question Sets</strong>.",
    payments: ["specialist-bundle"],
    img: specialistBundle,
    previewImg: specialistBundlePreview,
    alt: "two smiling people in a boardroom",
    link: "/products/specialist-bundle",
    category: "cdmp-specialist",
    showOnHomepage: true,
    price: 12000,
    bestValue: true,
    new: false,
    updated: false,
  },
  {
    name: "Specialist Guides",
    description: "Prepare for the CDMP Specialist Exams with these 15+ page guides.",
    payments: ["specialist-guide"],
    img: spnotes,
    previewImg: spnotesPreview,
    alt: "data professional wearing a suit",
    link: "/products/specialist-guides",
    category: "cdmp-specialist",
    showOnHomepage: true,
    price: 3500,
    bestValue: false,
    new: false,
    updated: false,
  },
  {
    name: "Specialist Question Sets",
    description: "Ace the CDMP Specialist Exams by practicing with Data Strategy Professionals Question Sets.",
    payments: ["specialist-question-set"],
    img: spqs,
    previewImg: spqsPreview,
    alt: "question sets",
    link: "/products/specialist-question-sets",
    category: "cdmp-specialist",
    showOnHomepage: true,
    price: 2500,
    bestValue: false,
    new: false,
    updated: false,
  },
  {
    name: "Career Coaching",
    description: `Build confidence in your data-related career with the support of one of our Career Coaches.
    At the conclusion of a 1-hour Zoom call, you will receive a detailed action plan with next steps.`,
    payments: ["career-coaching"],
    img: zoomWithSaif,
    previewImg: zoomPreview,
    alt: "woman having a conversation with a career coach",
    link: "/products/career-coaching",
    category: "service",
    showOnHomepage: true,
    price: 13500,
    bestValue: false,
    new: false,
    updated: false,
  },
  {
    name: "Resume & LinkedIn Review",
    description: `Our team will review your resume and LinkedIn profile to provide you with clear next steps
      to revise your job search documents and take the next step in your career journey.`,
    payments: ["resume-review"],
    img: resume,
    previewImg: resumePreview,
    alt: "two women discussing a resume",
    link: "/products/resume-review",
    category: "service",
    showOnHomepage: true,
    price: 8500,
    bestValue: false,
    new: false,
    updated: false,
  },
  {
    name: "Foundations of Data Strategy Poster",
    description: `This charming poster covers the focus areas of Data Governance, Data Quality, Data Architecture and Modeling, Master and Reference Data, and Data Warehousing and Business Intelligence.`,
    img: poster,
    previewImg: posterPreview,
    payments: ["poster"],
    alt: "poster",
    link: "/products/poster",
    category: "physical",
    showOnHomepage: true,
    price: 1200,
    bestValue: false,
    new: false,
    updated: false,
  },
  {
    name: "Data Strategist T-Shirt",
    description: "Represent your Data Strategist pride with this comfortable cotton t-shirt.",
    payments: ["t-shirt"],
    img: tshirt,
    previewImg: tshirtPreview,
    alt: "t-shirt",
    link: "/products/t-shirt",
    category: "physical",
    showOnHomepage: true,
    price: 2000,
    bestValue: false,
    new: false,
    updated: false,
  },
  {
    name: "Data Strategist Mug",
    description: `Enjoy your favorite hot beverage while representing your Data Strategist pride with this ceramic mug. Select white or black and 11 or 15 oz.`,
    payments: ["mug"],
    img: mug,
    previewImg: mugPreview,
    alt: "mug",
    link: "/products/mug",
    category: "physical",
    showOnHomepage: true,
    price: 750,
    bestValue: false,
    new: false,
    updated: false,
  },
  {
    name: "CIPP/E Bundle",
    description: `Become a Data Privacy professional with a <strong>90-day email course</strong>
    and access to <strong>100 practice questions</strong> about European Data Privacy legislation.`,
    payments: ["cippe-bundle"],
    img: cippeBundle,
    previewImg: cippeBundlePreview,
    alt: "product bundle",
    link: "/products/cippe-bundle",
    category: "data-privacy",
    showOnHomepage: true,
    price: 12500,
    bestValue: true,
    new: false,
    updated: false,
  },
  {
    name: "CIPP/E Study Plan",
    description: `Learn the fundamentals of GDPR with daily emails
      over 90-Day Study Plan that prepare you to ace the CIPP/E exam.`,
    payments: ["cippe-study-plan", "cippe-study-plan-continuation", "cippe-study-plan-free-trial"],
    img: flags,
    previewImg: flagsPreview,
    alt: "European Union Flags",
    link: "/products/cippe-study-plan",
    category: "data-privacy",
    showOnHomepage: true,
    price: 8900,
    bestValue: false,
    new: false,
    updated: false,
  },
  {
    name: "CIPP/E Question Sets",
    description: "Advance your knowledge with practice questions for the CIPP/E exam.",
    payments: ["cippe-question-set"],
    img: cippeQs,
    previewImg: cippeQsPreview,
    alt: "exam questions",
    link: "/products/cippe-question-sets",
    category: "data-privacy",
    showOnHomepage: true,
    price: 1800,
    bestValue: false,
    new: false,
    updated: false,
  },
  {
    name: "GDPR Essentials Course",
    description: "Master GDPR with this series of comprehensive video lessons.",
    payments: ["gdpr-essentials-course"],
    img: gdprCourse,
    previewImg: gdprCoursePreview,
    alt: "European parliament",
    link: "/products/gdpr-essentials-course",
    category: "data-privacy",
    showOnHomepage: false,
    price: 24900,
    bestValue: false,
    new: false,
    updated: false,
  },
  {
    name: "GDPR Mind Maps and Checklists",
    description: "Master the European Data Privacy regime with these mind maps and checklists.",
    payments: ["gdpr-mind-maps"],
    img: gdpr,
    previewImg: gdprPreview,
    alt: "GDPR mind map",
    link: "/products/gdpr-mind-maps",
    category: "data-privacy",
    showOnHomepage: true,
    price: 3000,
    bestValue: false,
    new: false,
    updated: false,
  },
  {
    name: "CAP Bundle",
    description:
      "Fully prepare for the Certified Analytics Practitioner exam with a <strong>65-day email course</strong> and access to <strong>100 practice questions</strong> about Data Analytics.",
    img: capBundle,
    previewImg: capBundlePreview,
    payments: ["cap-bundle"],
    alt: "product bundle",
    link: "/products/cap-bundle",
    category: "cap",
    showOnHomepage: true,
    price: 13900,
    bestValue: true,
    new: false,
    updated: false,
  },
  {
    name: "CAP Study Plan",
    description: `Maximize your time, energy, and motivation to become a Data Analyst with daily emails
      over 65-Day Study Plan that prepare you to take the Certified Analytics Professional (CAP) Exam.`,
    img: capStudent,
    previewImg: capStudentPreview,
    alt: "person studying for the cap exam",
    payments: ["cap-study-plan", "cap-study-plan-continuation", "cap-study-plan-free-trial"],
    link: "/products/cap-study-plan",
    category: "cap",
    showOnHomepage: true,
    price: 8900,
    bestValue: false,
    new: false,
    updated: false,
  },
  {
    name: "CAP Question Sets",
    description: "Advance your knowledge with practice questions for the Certified Analytics Professional (CAP) Exam.",
    img: capQs,
    previewImg: capQsPreview,
    payments: ["cap-question-set"],
    alt: "young woman holding books",
    link: "/products/cap-exam-questions",
    category: "cap",
    showOnHomepage: true,
    price: 2500,
    bestValue: false,
    new: false,
    updated: false,
  },
  {
    name: "Data Science Bundle",
    description:
      "Advance your understanding of Data Science with a <strong>12-week email course</strong> and <strong>100 practice questions</strong>.",
    payments: ["data-science-bundle"],
    img: dsBundle,
    previewImg: dsBundlePreview,
    alt: "hands stacked in huddle over center of table",
    link: "/products/data-science-bundle",
    category: "data-science",
    showOnHomepage: true,
    price: 6500,
    bestValue: true,
    new: false,
    updated: false,
  },
  {
    name: "Data Science Email Bootcamp",
    description: `Level up with 3-4 emails over 12 weeks providing the resources, articles, videos,
      and tutorials that will help you advance your Data Science career.`,
    payments: ["data-science-email-bootcamp"],
    img: bids,
    previewImg: bidsPreview,
    alt: "hands stacked in huddle over center of table",
    link: "/products/data-science-email-bootcamp",
    category: "data-science",
    showOnHomepage: true,
    price: 2900,
    bestValue: false,
    new: false,
    updated: false,
  },
  {
    name: "Data Science Practice Questions",
    description:
      "Advance your knowledge with practice questions designed to improve your understanding of Data Science.",
    payments: ["cds-question-set"],
    img: cdsQs,
    previewImg: cdsQsPreview,
    alt: "exam questions",
    link: "/products/data-science-practice-questions",
    category: "data-science",
    showOnHomepage: true,
    price: 1800,
    bestValue: false,
    new: false,
    updated: false,
  },
  {
    name: "Test Product",
    description: "Mystery Box.",
    payments: ["test-product1", "test-product2"],
    img: examReadiness,
    previewImg: examReadinessPreview,
    alt: "hands stacked in huddle over center of table",
    link: "/products/test-product",
    category: "career services",
    showOnHomepage: false,
    bestValue: false,
    new: false,
    updated: false,
  },
];

export default products;
